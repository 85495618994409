/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { CategoryModalComponent } from './category-modal/category-modal.component';
import { AddressSearchModalComponent } from './address-search-modal/address-search-modal.component';
import { DirectInfoModalComponent } from './direct-info-modal/direct-info-modal.component';

import { UserAddress } from '../../schema/1/schema-finger';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public closedDirectInfoModal = false;

  constructor(
    private modalController: ModalController
  ) { }

  /**
   * userAddress값을 채워주는 경우 '주소 수정'모드로 동작한다.
   */
  async presentAddressModal(userAddress?: UserAddress, callback?: () => void) {
    const modal = await this.modalController.create({
      component: AddressSearchModalComponent,
      componentProps: { userAddress, callback }
    });
    return await modal.present();
  }

  async presentCategoryModal(allCategories: string[], categories: string[], setCategories: (categories: string[]) => void) {
    const modal = await this.modalController.create({
      component: CategoryModalComponent,
      componentProps: { allCategories, categories, setCategories }
    });
    return await modal.present();
  }

  async presentDirectInfoModal(site: string) {
    const modal = await this.modalController.create({
      component: DirectInfoModalComponent,
      componentProps: {
        site,
        closed: () => { this.closedDirectInfoModal = true; }
      }
    });
    return modal.present();
  }
}
