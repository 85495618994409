/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { UnifiedMenuDoc } from '../../schema/3/schema';

const collectionPath = 'unifiedMenu';

@Injectable({
  providedIn: 'root'
})
export class UnifiedMenuService {
  public currentSite: string;

  public unifiedMenuForSite: UnifiedMenuDoc[];
  public latestUnifiedMenuForSiteSubject = new BehaviorSubject<UnifiedMenuDoc[]>([]);

  private unifiedMenuForSiteSubscription: Subscription;

  constructor(
    private db: AngularFirestore,
  ) { }

  public stopObservingForSite() {
    this.unifiedMenuForSite = undefined;
    this.unifiedMenuForSiteSubscription?.unsubscribe();
    this.currentSite = '';
  }

  public restartObservingForSite(site: string) {
    if (this.currentSite === site) {
      return;
    }

    this.unifiedMenuForSite = undefined;
    this.unifiedMenuForSiteSubscription?.unsubscribe();
    this.currentSite = site;
    this.observeMenu('site', site);
  }

  public async getUnifiedMenuForSite(site: string) {
    if (this.currentSite === site && this.unifiedMenuForSite) {
      return this.unifiedMenuForSite;
    }

    const queryFn: QueryFn = ref => ref.where('site', '==', site);
    const collection = this.db.collection<UnifiedMenuDoc>(collectionPath, queryFn);
    const snapshot = await collection.get().toPromise();
    const docs = snapshot.docs.map(doc => doc.data() as UnifiedMenuDoc);

    this.currentSite = site;
    this.unifiedMenuForSite = docs;
    this.latestUnifiedMenuForSiteSubject.next(docs);

    return docs;
  }

  /**
   * 주어진 key/value 조건에 맞는 메뉴 목록을 가져온다.
   *
   * @param key 'organization' | 'site' | 'room' | 'shopNo'
   */
  private observeMenu(key: 'organization' | 'site' | 'room' | 'shopNo', value: string) {
    // debugLog(`${this.constructor.name}::observeMenu for ${key}:${value}`);
    const queryFn: QueryFn = ref => {
      const query = ref.where(key, '==', value);
      return query;
    };

    const collection = this.db.collection<UnifiedMenuDoc>(collectionPath, queryFn);

    // 디버깅용
    // if (environment.production === false) {
    //   collection.stateChanges().pipe(
    //     map(actions => actions.map(action => {
    //       return { _type: action.type, ...action.payload.doc.data() };
    //     }))
    //   ).subscribe(menus => {
    //     for (const menu of menus) {
    //       // debugLog(`[${menu._id}] ${menu.room}/${menu.shopName}`);
    //     }
    //   });
    // }

    // valueChanges는 snapshopChanges에서 metadata는 필요없고 data()만 필요한 경우에 사용한다.

    if (key === 'site') {
      this.unifiedMenuForSiteSubscription = collection.valueChanges().subscribe(docs => {
        this.unifiedMenuForSite = docs;
        this.latestUnifiedMenuForSiteSubject.next(docs);
      });
    }
  }
}
