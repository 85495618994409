/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuardService } from './auth-guard.service';
import { InitGuardService } from './init-guard.service';
import { SitesComponent } from './pages/sites/sites.component';
import { ShopComponent } from './pages/shop/shop.component';
import { MenuFormComponent } from './form/menu-form/menu-form.component';
import { OrderFormComponent } from './form/order-form/order-form.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { HomeComponent } from './pages/home/home.component';
import { MyOrderComponent } from './pages/my-order/my-order.component';
import { ReceiptComponent } from './pages/receipt/receipt.component';
import { ReceiptGuardService } from './pages/receipt/receipt-guard.service';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';

import { MenuGuardService } from './core/2/menu-guard.service';
import { ShoppingCartGuardService } from './core/5/shopping-cart-guard.service';
import { OrderGuardService } from './core/6/order-guard.service';
import { SiteGuardService } from './core/6/site-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivate: [InitGuardService],
    canActivateChild: [AuthGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent
      },
      {
        path: 'page-not-found',
        pathMatch: 'full',
        component: PageNotFoundComponent
      },
      {
        path: 'my-order',
        pathMatch: 'full',
        component: MyOrderComponent
      },
      {
        path: 'my-order/:order',
        pathMatch: 'full',
        component: ReceiptComponent,
        canActivate: [ReceiptGuardService]
      },
      {
        path: ':site',
        canActivate: [SiteGuardService],
        children: [
          {
            path: '',
            component: SitesComponent,
          },
          {
            path: 'order',
            pathMatch: 'full',
            component: OrderFormComponent,
            canActivate: [OrderGuardService]
          },
          {
            path: 'cart',
            pathMatch: 'full',
            component: ShoppingCartComponent,
            canActivate: [ShoppingCartGuardService]
          },
          {
            path: ':shop/:food',
            pathMatch: 'full',
            component: MenuFormComponent,
            canActivate: [MenuGuardService],
          },
          {
            path: ':shop',
            pathMatch: 'full',
            component: ShopComponent,
          },
        ]
      },
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
