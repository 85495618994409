/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  version: '20220617-142109',
  firebaseProd: {
    apiKey: 'AIzaSyDOwd8SczxpCH5U9R-5ikRNHS-iGgPWkgU',
    authDomain: 'toe-prod.firebaseapp.com',
    databaseURL: 'https://toe-prod.firebaseio.com',
    projectId: 'toe-prod',
    storageBucket: 'toe-prod.appspot.com',
    messagingSenderId: '1046149416932',
    appId: '1:1046149416932:web:44ae341281a8fa41'
  },
  firebase: {
    apiKey: 'AIzaSyCGwJjdpxjXYKetDqcxhC-5phDkuJAsNG4',
    authDomain: 'toe-dev-2019.firebaseapp.com',
    databaseURL: 'https://toe-dev-2019.firebaseio.com',
    projectId: 'toe-dev-2019',
    storageBucket: 'toe-dev-2019.appspot.com',
    messagingSenderId: '825253517610',
    appId: '1:825253517610:web:8ac0c337694cf3fc'
  },
  kakaoAPIKey: 'dfaeca1d959a4d9298745d092ee9bc90',
  kiccMallId: 'T0010131',
  paymentServer: 'https://dev.kicc.ghostaurant.co',
  imageServer: 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen',
  email: 'face@ghostaurant.co'
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
