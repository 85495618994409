/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import fecha from 'fecha';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';

import { UnifiedOrder } from '../../schema/3/schema';

import { UtilService } from '../../core/1/util.service';
import { getRequestAuth, getConfirmAuth } from '../../core/1/sms-api';
import { LocalStorageService } from '../../core/1/local-storage.service';
import { normalizingTel } from '../../core/2/util';
import { diffTime, trimOrganization } from '../../core/2/util';
import { UnifiedOrderService } from '../../core/4/unified-order.service';
import { SiteService } from '../../core/4/site.service';
import { DirectLogService } from '../../core/5/direct-log.service';

import { LoadingService } from '../../shared/loading/loading.service';

interface MyOrderViewModel {
  orderNo: string;
  roomNo: string;
  site: string;
  shopName: string;
  paymentAmount: number;
  contextStatusCode: number;
  orderStatus: string;
  orderDate: number;
  foods: string[];
  cookMinutes: number;
  deliveryMinutes: number;
  isDelivery: boolean;
  /** 분 */
  timer?: number;
}

@Component({
  selector: 'app-my-order',
  templateUrl: './my-order.component.html',
  styleUrls: ['./my-order.component.scss']
})
export class MyOrderComponent implements OnInit, OnDestroy, AfterViewInit {
  monthRange = 6;
  defaultHref = '';

  orderStatusMap = {
    10: '주문완료',
    20: '접수완료',
    40: '조리완료',
    60: '배송중',
    70: '완료',
    80: '주문취소'
  };
  userTelForm: FormControl;
  authCodeForm: FormControl;

  initialized = false;
  confirmAuth = false;
  confirmUserTel: string;
  unifiedOrderForUserTel: UnifiedOrder[] = [];
  orderList: MyOrderViewModel[] = [];

  userTel: string;
  sessionId: string;
  expires: string;

  private unifiedOrderForUserTelSubscription: Subscription;
  private pickupTimeCheckerSubscription: Subscription;

  constructor(
    private localStorageService: LocalStorageService,
    private unifiedOrderService: UnifiedOrderService,
    private siteService: SiteService,
    private loadingService: LoadingService,
    private utilService: UtilService,
    private location: Location,
    private router: Router,
    private directLogService: DirectLogService
  ) { }

  ngOnInit() {
    const { site } = this.location.getState() as { site: string };
    this.defaultHref = site ? site : 'gk-kangnam';
    this.userTelForm = new FormControl({ value: '', disabled: this.confirmAuth }, this.userTelValidator());
    this.authCodeForm = new FormControl({ value: '', disabled: this.confirmAuth }, this.authCodeValidator());
    this.initialization();
  }

  ngOnDestroy() {
    this.unifiedOrderForUserTelSubscription?.unsubscribe();
    this.pickupTimeCheckerSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    this.calculatePickupTimeFor();
  }

  /**
   * 인증정보를 초기화합니다.
   */
  public async resetConfirm() {
    await this.loadingService.presentLoading();
    this.directLogService.logDirect('click', '인증정보 삭제', '주문내역');

    try {
      this.localStorageService.removeItem('userTel');
      this.localStorageService.removeItem('expires');
      this.localStorageService.removeItem('authSMS');
      this.userTel = undefined;
      this.expires = undefined;
      this.sessionId = undefined;

      this.confirmAuth = false;
      this.userTelForm.setValue('');
      this.userTelForm.enable();
      this.authCodeForm.enable();
    } catch (error) {
      this.directLogService.logDirect('error', `resetConfirm에서 에러 발생 : ${error}`, '주문내역');
    }
    this.loadingService.dismissLoading();
  }

  /******************************************************************
   * [전화번호 문자인증]
   * 1. sendAuthSMS()     인증 번호 문자 발송
   * 2. confirmSMSCode()  인증 번호 문자 확인
   ******************************************************************/
  public async sendAuthSMS() {
    // 인증 문자 확인 시 요청을 보낸 번호와 동일한지 비교하기 위해 저장한다.
    this.confirmUserTel = this.userTelForm.value;
    const userTel = this.confirmUserTel.replace(/-/g, '');

    this.directLogService.logDirect('input', `전화번호: ${this.confirmUserTel}`, '주문내역');
    this.directLogService.logDirect('click', this.sessionId ? '재전송' : '인증문자 전송', '주문내역');

    try {
      await this.loadingService.presentLoading();
      const response = await getRequestAuth(userTel);

      const { sessionId } = await response.json() as { sessionId: string };
      this.sessionId = sessionId;
      this.authCodeForm.setValue('');
      this.directLogService.logDirect('info', `문자전송 성공 - ${this.confirmUserTel}`, '주문내역');
    } catch (error) {
      this.utilService.toastError('인증 문자 발송에 실패했습니다. 잠시후 다시 시도해주세요.');
      this.directLogService.logDirect('error', `문자전송 실패 - ${this.confirmUserTel}, error: ${error}`, '주문내역');
    }
    this.loadingService.dismissLoading();
  }

  public async confirmSMSCode() {
    const userTel = this.userTelForm.value;
    const authCode = this.authCodeForm.value;

    this.directLogService.logDirect('input', `인증번호 입력: ${authCode}`, '주문내역');
    this.directLogService.logDirect('click', '인증번호 확인', '주문내역');

    try {
      this.loadingService.presentLoading();
      const response = await getConfirmAuth(this.sessionId, authCode);

      const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };
      // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
      this.expires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
      this.confirmAuth = (result === 'success');

      if (reason !== null) {
        const msg = (reason === 'autoCode mismatch') ? '잘못된 인증문자입니다.<br>다시 입력해주세요.' : reason;
        this.utilService.toastInfo(msg, undefined, 3000);
        this.directLogService.logDirect('info', `문자인증 실패 - ${userTel}, reason: ${reason}`, '주문내역');
      } else {
        this.directLogService.logDirect('info', `문자인증 성공 - ${userTel}`, '주문내역');
        this.saveLocalUserState();
        this.userTel = userTel;
        // 인증이 완료된 전화번호에 해당하는 주문 정보를 불러온다.
        const now = new Date();
        const endDate = fecha.format(new Date(now.setMonth(now.getMonth() - this.monthRange)), 'YYYY-MM-DDTHH:mm:ss+09:00');
        const flatUserTel = userTel.replace(/-/g, '');
        this.unifiedOrderService.observeOrderFor(flatUserTel, endDate);
        if (this.unifiedOrderForUserTelSubscription) {
          this.unifiedOrderForUserTelSubscription.unsubscribe();
          this.unifiedOrderForUserTelSubscription = undefined;
        }
        this.observeUnifiedOrder();
      }
    } catch (error) {
      this.directLogService.logDirect('error', `문자인증 실패 - ${userTel}, error: ${error}`, '주문내역');
      this.utilService.toastError('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
    }
    this.loadingService.dismissLoading();
  }

  public goToReceipt(orderNo: string) {
    this.directLogService.logDirect('click', `주문상세 - orderNo: ${orderNo}`, '주문내역');
    this.router.navigateByUrl(`/my-order/${orderNo}`);
  }

  private async initialization() {
    const userTel = this.checkUserTel();

    if (userTel) {
      let confirmAuth = false;
      const normalizedTel = normalizingTel(userTel);

      // 인증된 번호와 목록이 있는 사용자는 재인증을 건너뛴다.(인증 소요시간을 건너뛰기 위함)
      if ((userTel === this.unifiedOrderService.userTel)
        && (this.unifiedOrderService.unifiedOrderForUserTel)
        && (this.unifiedOrderService.unifiedOrderForUserTel.length > 0)) {
        confirmAuth = true;
        this.observeUnifiedOrder();
      } else {
        await this.loadingService.presentLoading();
        confirmAuth = await this.autoConfirm();
        await this.loadingService.dismissLoading();
        if (confirmAuth) {
          const now = new Date();
          const endDate = fecha.format(new Date(now.setMonth(now.getMonth() - this.monthRange)), `YYYY-MM-DDTHH:mm:ss+09:00`);
          this.unifiedOrderService.observeOrderFor(userTel, endDate);

          this.observeUnifiedOrder();
          this.directLogService.logDirect('info', `로컬 정보로 재인증 완료 - ${normalizedTel}`, '주문내역');
        } else {
          this.directLogService.logDirect('info', `로컬 정보의 기간 만료로 정보 초기화 - ${normalizedTel}`, '주문내역');
        }
      }

      this.userTel = normalizedTel;
      this.userTelForm.setValue(userTel);
      this.confirmAuth = confirmAuth;
      if (confirmAuth) {
        this.userTelForm.disable();
        this.authCodeForm.disable();
      } else {
        this.userTelForm.enable();
        this.authCodeForm.enable();
      }
    }
    this.initialized = true;
  }

  private observeUnifiedOrder() {
    this.unifiedOrderForUserTelSubscription = this.unifiedOrderService.latestUnifiedOrderForUserTelSubject.subscribe(orderDoc => {
      this.orderList = orderDoc
        .filter(order => (
          // 삭제, 결제대기는 주문 내역에서 제외한다.
          this.orderStatusMap[order.contextStatusCode]
          // 직접방문, 손가락 주문내역만을 보여준다.
          && ((order.createdBy === 'face') || (order.createdBy === 'fingerFace'))
        ))
        .map(order => {
          const fechaDate = fecha.format(fecha.parse(order.orderDate, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
          const orderDate = Date.parse(fechaDate);
          const isDelivery = order.deliveryType === 'DELIVERY';

          const calculatedTime = isDelivery
            // 배달 주문인 경우 접수완료(20)부터 완료(70)사이에 시간을 보여준다.
            ? (order.contextStatusCode >= 20 && order.contextStatusCode < 70)
              ? this.calculatePickupTime(Date.parse(fechaDate), order.deliveryMinutes ?? 0)
              : 0
            // 테이크아웃 주문인 경우 접수완료부터 조리완료 사이에만 시간을 보여주면된다.
            : (order.contextStatusCode === 20)
              ? this.calculatePickupTime(Date.parse(fechaDate), order.cookMinutes ?? 0)
              : 0;

          return {
            orderNo: order.orderNo,
            roomNo: order.room.split('-')[2],
            site: trimOrganization(this.siteService.sites[order.site].siteName),
            shopName: order.shopName,
            paymentAmount: order.orderAmount + order.deliveryTip - (order.discount ?? 0),
            contextStatusCode: order.contextStatusCode,
            orderStatus: this.orderStatusMap[order.contextStatusCode],
            isDelivery,
            orderDate,
            foods: order.foods.map(food => food.mergedName),
            cookMinutes: order.cookMinutes ?? 0,
            deliveryMinutes: order.deliveryMinutes ?? 0,
            timer: calculatedTime > 0 ? calculatedTime : undefined
          };
        })
        .sort((a, b) => b.orderDate - a.orderDate);
    });
  }

  /**
   * ms(기본 5초)마다 남은 조리완료 시간을 나타내는 UI를 갱신한다.
   */
  private calculatePickupTimeFor(ms = 5000) {
    this.pickupTimeCheckerSubscription = timer(0, ms).subscribe(() => {
      this.orderList.forEach(order => {
        if (order.contextStatusCode >= 20 && order.contextStatusCode < 70) {
          if (order.isDelivery) {
            const calculatedDeliveryTime = this.calculatePickupTime(order.orderDate, order.deliveryMinutes);
            order.timer = calculatedDeliveryTime > 0 ? calculatedDeliveryTime : undefined;
          } else if (order.contextStatusCode === 20) {
            const calculatedTakeoutTime = this.calculatePickupTime(order.orderDate, order.cookMinutes);
            order.timer = calculatedTakeoutTime > 0 ? calculatedTakeoutTime : undefined;
          }
        }
      });
    });
  }

  /**
   * UserTel의 유무 및 전화번호 형식 체크
   */
  private checkUserTel() {
    if (!this.localStorageService.isExist('userTel')) { return false; }

    const userTel = this.localStorageService.getValue<'userTel'>('userTel');
    const normalizedTel = normalizingTel(userTel);
    const match = normalizedTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
    if (match === null) {
      // 잘못된 형식의 전화번호가 저장되어있는 경우 삭제한다.
      this.localStorageService.removeItem('userTel');
      return;
    }

    this.directLogService.logDirect('info', '로컬 정보 불러오기 완료', '주문내역');
    return userTel;
  }

  private saveLocalUserState() {
    try {
      const userTel = this.userTelForm.value.replace(/-/g, '');
      const authCode = this.authCodeForm.value;

      this.localStorageService.setItem('userTel', userTel);
      this.localStorageService.setItem('expires', this.expires);
      this.localStorageService.setItem('authSMS', {
        sessionId: this.sessionId,
        authCode
      });
    } catch (error) {
      this.directLogService.logDirect('error', `saveLocalUserState에서 예외 발생 : ${error}`, '주문내역');
    }

    // 인증 완료 및 저장까지 완료되어 입력창을 막는다.
    this.userTelForm.disable();
    this.authCodeForm.disable();
  }

  private async autoConfirm() {
    if (!this.localStorageService.isExist('authSMS')) { return false; }
    if (!this.localStorageService.isExist('expires')) { return false; }
    const localExpires = this.localStorageService.getValue<'expires'>('expires');
    const localAuthSMS = this.localStorageService.getValue<'authSMS'>('authSMS');

    if (localAuthSMS.sessionId === undefined) {
      this.directLogService.logDirect('error', `로컬 정보로 재인증 실패 - sessionId가 undefiend입니다.`, '주문내역');
      // 유요하지 않은 인증정보는 모두 삭제한다.
      this.localStorageService.removeItem('expires');
      this.localStorageService.removeItem('authSMS');
      return false;
    }

    const isExpired = new Date().getTime() > new Date(localExpires).getTime();
    if (!isExpired) {
      try {
        const response = await getConfirmAuth(localAuthSMS.sessionId, localAuthSMS.authCode);
        const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };

        if (result !== 'success') {
          const log = `로컬 정보로 재인증 실패 - sessionId: ${localAuthSMS.sessionId}, authCode: ${localAuthSMS.authCode}, reason ${reason}`;
          this.directLogService.logDirect('error', log, '주문내역');
        } else {
          const newExpires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
          this.localStorageService.setItem('expires', newExpires);
          return true;
        }
      } catch (error) {
        this.directLogService.logDirect('error', `authConfirm에서 예외 발생 : ${error}`, '주문내역');
      }
    }

    // 유요하지 않은 인증정보는 모두 삭제한다.
    this.localStorageService.removeItem('expires');
    this.localStorageService.removeItem('authSMS');
    return false;
  }

  private calculatePickupTime(orderDate: number, cookMinutes: number) {
    const now = new Date();
    const cookDate = new Date(orderDate + cookMinutes * 60000);
    const diff = diffTime(now, cookDate);
    return diff.m;
  }

  private userTelValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const { value } = control;

      const normalizedTel = normalizingTel(value);
      if (value !== normalizedTel) {
        this.userTelForm.setValue(normalizedTel);
      }

      if (value && value.length > 0) {
        const match = value.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.' };
        }
      } else {
        return { reason: '전화번호가 필요합니다.' };
      }
    };
  }

  private authCodeValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const { value } = control;

      if (!value || value.length === 0) {
        return { reason: '인증번호를 입력해주세요.' };
      }
    };
  }
}
