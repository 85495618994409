/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { format } from 'date-fns-tz';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { BehaviorSubject, Subscription } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import {
  UnifiedOrder,
  UnifiedOrderDoc,
  UnifiedOrderContextStatusCode
} from '../../schema/3/schema';

import { checkIsDirect } from '../1/common';
import { LogService } from '../3/log.service';

const unifiedOrderCollectionPath = 'unifiedOrder';

@Injectable({
  providedIn: 'root'
})
export class UnifiedOrderService {
  public isDirect = checkIsDirect();
  public userTel: string;
  public unifiedOrderForUserTel: UnifiedOrderDoc[];
  public latestUnifiedOrderForUserTelSubject = new BehaviorSubject<UnifiedOrderDoc[]>([]);
  public unifiedOrderForUserTelSubscription: Subscription;

  constructor(
    private db: AngularFirestore,
    private logService: LogService
  ) { }

  // docRef의 id값을 미리 생성할 때 사용한다.
  getFingerDocId() {
    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc();
    return docRef.id;
  }

  async createFingerOrder(order: Partial<UnifiedOrder>, docRefId: string) {
    const id = `finger-${docRefId}`;
    let ret = 'OK';

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(id);

    const doc: Partial<UnifiedOrderDoc> = {
      ...order, ...{
        _id: id,
        _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,

        // unifiedOrderContext에서 이사왔다.
        createdBy: 'fingerFace',
        contextStatusCode: order.orderStatusCode as unknown as UnifiedOrderContextStatusCode,
        cookMinutes: 0,
        adminMemo: ''
      }
    };

    // console.dir(doc);
    try {
      await this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc);
    } catch (err) {
      ret = `${id}에 대한 unifiedOrder 생성 실패 : ${err.message}`;
      return ret;
    }

    return ret;
  }

  updateOrder(order: Partial<UnifiedOrder>) {
    const id = order._id;

    if (id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(id);
    const doc: Partial<UnifiedOrderDoc> = {
      ...order, ...{
        _timeUpdate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      }
    };

    return this.db.doc<Partial<UnifiedOrderDoc>>(docRef).update(doc);
  }

  mergeOrder(order: Partial<UnifiedOrder>) {
    const id = order._id;

    if (id == null) {
      throw new Error('_id field must exist');
    }

    const docRef = this.db.firestore.collection(unifiedOrderCollectionPath).doc(id);
    const doc: Partial<UnifiedOrderDoc> = {
      ...order,
      _timeUpdate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
    };

    return this.db.doc<Partial<UnifiedOrderDoc>>(docRef).set(doc, {
      merge: true
    });
  }

  // 주문내역을 불러온다.
  public observeOrderFor(userTel: string, endDate: string) {
    this.userTel = userTel;
    const queryFn: QueryFn = ref => {

      const query = ref
        .where('userTel', '==', userTel)
        .where('orderVendor', '==', 'ghostkitchen')
        .orderBy('orderDate', 'desc')
        .endAt(endDate);

      return query;
    };

    const collection = this.db.collection<UnifiedOrderDoc>(unifiedOrderCollectionPath, queryFn);

    this.unifiedOrderForUserTelSubscription = collection.valueChanges().subscribe(docs => {
      const filterWithDeliveryType = docs.filter(doc => doc.deliveryType === (this.isDirect ? 'DELIVERY' : 'TAKEOUT'));
      this.unifiedOrderForUserTel = filterWithDeliveryType;
      this.latestUnifiedOrderForUserTelSubject.next(filterWithDeliveryType);
    });
  }

  async getSimpleNo(unifiedOrder: Partial<UnifiedOrderDoc>, maxNumber = 8999, minNumber = 1) {
    const db = this.db.firestore;

    const date = format(new Date(), 'yyyyMMdd');
    const docId = date + '-' + unifiedOrder.site;

    const collection = 'tally';
    const docRef = db.collection(collection).doc(docId);

    try {
      return await db.runTransaction(async transaction => {
        const docSnapshot = await transaction.get(docRef);
        if (!docSnapshot.exists) {
          // 1. 없으면 생성
          transaction.set(docRef, {
            _timeCreate: new Date(),
            date,
            site: unifiedOrder.site,
            num: 0
          });
          const newNumber = minNumber;
          // console.log(`시작 => ${site}:${newNumber}`);
          return newNumber;
        } else {
          // 2. 기존 업데이트
          // tslint:disable-next-line:no-non-null-assertion
          const tallyDoc = docSnapshot.data()!;
          const currentNum = tallyDoc?.num ?? 0;
          transaction.update(docRef, {
            _timeUpdate: new Date(),
            num: currentNum + 1
          });
          // minNumber ~ maxNumber 사이의 수가 오도록 조정한다.
          const newNumber = ((currentNum + 1) % (maxNumber - minNumber + 1)) + minNumber;
          // console.log(`지금까지 => ${site}:${newNumber}`);
          return newNumber;
        }
      });
    } catch (error) {
      this.logService.logOrder(unifiedOrder as UnifiedOrder, `getSimpleNo에서 에러발생, error: ${error}`, 'error');
      // 에러가 발생한 경우에 9000 ~ 9999 사이의 수를 랜덤하게 생성한다.
      const newNumber = maxNumber + Math.floor(Math.random() * 1000);
      // console.log(`error가 발생한 경우에는 뒤 번호 1000개 중에 한 개를 사용한다. => ${site}:${newNumber}`);
      return newNumber;
    }
  }
}
