/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';

import { UserState } from '../../schema/1/schema-finger';

const prefix = 'direct_';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public storage: Storage;
  public userState: Partial<UserState> = {};

  constructor() {
    this.storage = window.localStorage;
  }

  public checkAvailable() {
    try {
      const x = '__storage_test__';
      this.storage.setItem(x, x);
      this.storage.removeItem(x);

      return true;
    } catch (error) {
      return error instanceof DOMException && (
        // everything except Firefox
        error.code === 22 ||
        // Firefox
        error.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        error.name === 'QuotaExceededError' ||
        // Firefox
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
        (this.storage && this.storage.length !== 0);
    }
  }

  public isExist(key: keyof UserState) {
    if (this.storage.getItem(`${prefix}${key}`)
      && this.storage.getItem(`${prefix}${key}`).length > 0) {
      this.getValue(key);
      return (this.userState[key] !== null);
    }

    return false;
  }

  public getValue<N extends keyof UserState>(key: keyof UserState) {
    const value = this.storage.getItem(`${prefix}${key}`);
    this.userState[key] = JSON.parse(value);

    return this.userState[key] as UserState[N];
  }

  public setItem(key: keyof UserState, value: UserState[keyof UserState]) {
    try {
      this.storage.setItem(`${prefix}${key}`, JSON.stringify(value));
    } catch (error) {
      return error;
    }
  }

  public removeItem(key: keyof UserState) {
    this.storage.removeItem(`${prefix}${key}`);
  }

  public clearStorage() {
    this.storage.clear();
    this.userState = undefined;
  }
}
