/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { VersionDoc } from '../../schema/3/schema';

import { LogService } from '../3/log.service';
import { environment } from '../../../environments/environment';

const myVersion = environment.version;
const versionDocPath = 'conf/version';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  // tslint:disable-next-line: variable-name
  public _latestVersion = myVersion;
  public isDifferent = false;
  public latestVersionSubject = new BehaviorSubject<string>(this._latestVersion);

  constructor(
    private db: AngularFirestore,
    private alertController: AlertController,
    private logService: LogService
  ) { }

  get myVersion() {
    return environment.version;
  }

  get latestVersion() {
    return this._latestVersion;
  }

  public observeVersion() {
    const doc = this.db.doc<VersionDoc>(versionDocPath);

    doc.valueChanges().subscribe(version => {
      if (version === undefined) {
        // 'Could not reach Cloud Firestore backend.'와 같은 에러 발생시 데이터 읽기가 실패해서 undefined가 올 수 있다.
        this.noticeAlertConfirm();
        this.logService.error(`VersionService에서 에러 발생: version doc = undefined 입니다.`);
        return;
      }

      this._latestVersion = version.fingerFace2Ver;
      console.log(`lateast version = ${this.latestVersion}`);
      this.latestVersionSubject.next(this._latestVersion);
    });
  }

  async noticeAlertConfirm() {
    const options = {
      message: '통신 중 일시적인 오류가 발생하였습니다.',
      backdropDismiss: false,
      buttons: [
        {
          text: '다시 시도',
          handler: async () => {
            await this.logService.info(`versionService에러로 reload 실행`);
            window.location.reload();
          }
        }
      ]
    };

    const alert = await this.alertController.create(options);
    await alert.present();
  }
}
