/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { AngularFirestore } from '@angular/fire/firestore';

import { DirectAnalyticsDoc } from '../../schema/4/schema-direct';

import { instanceId } from '../1/common';
import { AddressService } from '../1/address.service';
import { LocalStorageService } from '../1/local-storage.service';
import { VersionService } from '../4/version.service';

@Injectable({
  providedIn: 'root'
})
export class DirectLogService {
  public organization: string;
  public site: string;

  private instanceId: string;

  constructor(
    private db: AngularFirestore,
    private localStorageService: LocalStorageService,
    private versionService: VersionService,
    private addressService: AddressService
  ) {
    this.instanceId = this.getInstanceId();
  }

  /**
   *
   * @param event 'start' | 'goto' | 'click' | 'popup' | 'input' | 'error' | 'info'
   * @param content event에 따른 content의 예는 다음과 같다.
   * - start: URL
   * - goto: URL
   * - click: '18,000원 주문하기'
   * - popup: '주소 입력 요청'
   * - input: '상세 주소에 8층 입력'
   * - error: 'firestore 에러 발생'
   * - info: '결제 후 돌아올 때까지 10초 소요'
   * @param context 해당 이벤트가 발생한 현재 페이지를 기록한다.
   */
  public async logDirect(event: DirectAnalyticsDoc['event'], content: string, context?: string) {
    const collectionPath = 'directAnalytics';

    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const publicIP = await this.addressService.findPublicAddress();

    const doc: DirectAnalyticsDoc = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      organization: this.organization,
      version: this.versionService.myVersion,
      session: this.instanceId,
      event,
      content,
      publicIP
    };

    if (this.site) {
      doc.site = this.site;
    }

    if (context) {
      doc.context = context;
    }

    return this.db.doc<DirectAnalyticsDoc>(docRef).set(doc);
  }

  /**
   * 리로드와 상관없이 동일한 사용자를 확인하기 위해 localStorage에 instanceId를 저장한다.
   */
  private getInstanceId() {
    const isStorageAvailalbe = this.localStorageService.checkAvailable();

    // localStorage사용이 불가능한 경우
    if (isStorageAvailalbe !== true) {
      return instanceId;
    }

    if (this.localStorageService.isExist('instanceId')) {
      return this.localStorageService.getValue<'instanceId'>('instanceId');
    } else {
      this.localStorageService.setItem('instanceId', instanceId);
      return instanceId;
    }
  }
}
