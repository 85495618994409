/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { checkIsDirect } from '../1/common';
import { UtilService } from '../1/util.service';
import { AlertNoticeService } from '../1/alert-notice.service';
import { ShoppingCartService } from '../4/shopping-cart.service';
import { UserAddressService } from '../5/user-address.service';
import { DirectLogService } from '../5/direct-log.service';

@Injectable({
  providedIn: 'root'
})
export class OrderGuardService implements CanActivate {
  constructor(
    private router: Router,
    private utilService: UtilService,
    private shoppingCartService: ShoppingCartService,
    private userAddressService: UserAddressService,
    private alertNoticeService: AlertNoticeService,
    private directLogService: DirectLogService,
  ) { }

  promiseForInit(route: ActivatedRouteSnapshot) {
    const site = route.paramMap.get('site');
    const parsedUrl = this.router.parseUrl(site);

    const isDirect = checkIsDirect();
    const userAddress = this.userAddressService.userAddress;

    return new Promise<boolean | UrlTree>((resolve) => {
      const shopNo = this.shoppingCartService.shopNo;

      if (shopNo === undefined) {
        resolve(parsedUrl);
        this.utilService.toastInfo(`장바구니가 비어있습니다.`, undefined, 3000);
      } else if (isDirect && userAddress === undefined) {
        // 다이렉트 모드에서 사용자 주소 정보가 없으면 메인 화면(site)으로 이동시킨다.
        resolve(parsedUrl);
        this.directLogService.logDirect('popup', '주소 입력 요청', 'order-guard');
        this.alertNoticeService.noticeAlert('거리에 따른 할인 적용을 위해<br>주소를 입력해주세요.');
      } else {
        resolve(true);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.promiseForInit(route);
  }
}
