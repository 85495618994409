/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { ShoppingCartService } from '../4/shopping-cart.service';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartGuardService implements CanActivate {
  constructor(
    private router: Router,
    private shoppingCartService: ShoppingCartService,
  ) { }

  promiseForInit(route: ActivatedRouteSnapshot) {
    const site = route.paramMap.get('site');
    const parsedUrl = this.router.parseUrl(site);

    return new Promise<boolean | UrlTree>((resolve) => {
      // 장바구니에는 업소 정보가 있어야한다.
      if (this.shoppingCartService.shopDetail) {
        resolve(true);
      } else {
        resolve(parsedUrl);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.promiseForInit(route);
  }
}
