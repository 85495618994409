/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IonContent } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { UserAddress } from '../../schema/1/schema-finger';
import { ViewOption } from '../../schema/1/schema-view-model';
import { SiteDoc } from '../../schema/3/schema';

import { checkIsDirect } from '../../core/1/common';
import { UtilService } from '../../core/1/util.service';
import { LocalStorageService } from '../../core/1/local-storage.service';
import { getCookie, trimOrganization } from '../../core/2/util';
import { SiteService } from '../../core/4/site.service';
import { ShoppingCartService } from '../../core/4/shopping-cart.service';
import { DirectLogService } from '../../core/5/direct-log.service';
import { UserAddressService } from '../../core/5/user-address.service';

import { ModalService } from '../../shared/modal/modal.service';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit, OnDestroy {
  @ViewChild('scrollArea', { static: true }) ionContent: IonContent;
  public isDirect = checkIsDirect();
  public siteName: string;
  public siteDoc: SiteDoc;
  public userAddress: UserAddress;
  public shortenedAddress: string;

  public viewOption: ViewOption = ViewOption.SHOP;
  public productsInCart = 0;
  public discount = 0;
  public hideDiscountPopover = false;

  private initialized = false;
  private destroySignal = new Subject<void>();

  constructor(
    private shoppingCartService: ShoppingCartService,
    private siteSerivce: SiteService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private userAddressService: UserAddressService,
    private modalService: ModalService,
    private directLogService: DirectLogService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
    this.observeParam();
    this.observeShoppingCart();

    if (this.isDirect) {
      this.userAddressService.latestUserAddressSubject
        .pipe(takeUntil(this.destroySignal))
        .subscribe(userAddress => {
          this.shortenedAddress = userAddress?.address_road?.replace(/\S+/, '').trim();
          this.userAddress = userAddress;
          this.discount = userAddress?.discount ?? 0;
          this.hideDiscountPopover = this.localStorageService.getValue('confirmDiscount') === 'Y';
        });
    }
  }

  ngOnDestroy() {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  public confirmDiscount() {
    this.directLogService.logDirect('click', `다이렉트 할인 ${Intl.NumberFormat().format(this.discount)}원`, '지점');
    this.localStorageService.setItem('confirmDiscount', 'Y');
    this.hideDiscountPopover = true;
  }

  public logCallServiceCenter() {
    this.directLogService.logDirect('click', '고객센터', '지점');
  }

  public logBizInfo() {
    this.directLogService.logDirect('click', '사업자정보 확인', '지점');
  }

  public async presentAddressModal() {
    const context = this.userAddress ? '지점(입력된 주소 있음)' : '지점';
    this.directLogService.logDirect('click', '주소 모달 호출', context);

    try {
      await this.modalService.presentAddressModal(this.userAddress);
    } catch (error) {
      this.directLogService.logDirect('error', `주소 모달 호출 실패: ${error}`, context);
    }
  }

  /******************************************************************
   * [initialize & observing]
   ******************************************************************/
  observeParam() {
    this.route.params
      .pipe(takeUntil(this.destroySignal))
      .subscribe(param => {
        const { site } = param;
        const siteDoc = this.siteSerivce.sites[site];

        if (siteDoc) {
          document.title = siteDoc.siteName;
          this.siteName = trimOrganization(siteDoc.siteName);

          // 최초 지점 상태가 설정된 경우에만 팝업을 연다.
          // if (this.initialized === false) {
          //   const isDirectPopupShow = getCookie('isDirectPopupShow');
          //   if (isDirectPopupShow !== 'N' && this.modalService.closedDirectInfoModal === false) {
          //     this.directLogService.logDirect('popup', '다이렉트 소개 모달', '지점');
          //     this.modalService.presentDirectInfoModal(site);
          //   }
          //   this.initialized = true;
          // }

        } else {
          this.utilService.toastError('잘못된 주소입니다.', undefined, 5000);
          this.directLogService.logDirect('error', `잘못된 지점 URL(${site})로 접근`, '지점');
          this.router.navigateByUrl('');
        }

        this.siteDoc = siteDoc;
        // 장바구니 초기화
        if (this.shoppingCartService.shopDetail && this.shoppingCartService.shopDetail.site !== site) {
          this.shoppingCartService.emptyCart();
          this.directLogService.logDirect('info', `사이트 주소 변경(${this.shoppingCartService.shopDetail.site} -> ${site})으로 장바구니 초기화`, '지점');
        }
      });
  }

  observeShoppingCart() {
    this.shoppingCartService.latestUnifiedOrderFoodsSubject
      .pipe(takeUntil(this.destroySignal))
      .subscribe(unifiedOrderFoods => {
        this.productsInCart = unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);
      })
      ;
  }

  viewOptionChanged(event: CustomEvent) {
    const { value } = event.detail;
    this.viewOption = value;
    this.directLogService.logDirect('click', `탭 선택 - ${this.viewOption === '1' ? '업소 보기' : '모든 업소 메뉴 보기'}`, '지점');
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  goShoppingCart() {
    this.directLogService.logDirect('click', '장바구니', '지점');
    this.router.navigate(['cart'], { relativeTo: this.route });
  }

  goToMyOrder() {
    this.directLogService.logDirect('click', '주문내역', '지점');
    this.router.navigateByUrl('my-order', { state: { site: this.siteDoc._id } });
  }

  openDestMap() {
    const { lat, lng, siteName } = this.siteDoc;
    const placeName = encodeURI(siteName);
    const url = 'http://map.daum.net/link/map/' + placeName + `,${lat},${lng}`;

    this.directLogService.logDirect('click', `업소 위치 보기 - ${siteName}`, '지점');

    // 상세 내용 추가
    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      url,
      'centerscreen,alwaysRaised,noreferrer'
    );
  }

  scrollToTop(animation?: boolean) {
    if (animation) {
      this.directLogService.logDirect('click', '상단으로 스크롤 이동', '지점');
    }
    this.ionContent.scrollToTop(animation ? 700 : undefined);
  }
}
