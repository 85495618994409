/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import fecha from 'fecha';
import { filter } from 'rxjs/operators';
import { Subscription, combineLatest } from 'rxjs';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Platform, MenuController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { environment } from '../environments/environment';
import { GtagService } from './core/1/gtag.service';
import { getConfirmAuth } from './core/1/sms-api';
import { AlertNoticeService } from './core/1/alert-notice.service';
import { UnifiedMenuService } from './core/1/unified-menu.service';
import { LocalStorageService } from './core/1/local-storage.service';
import { AuthService } from './core/2/auth.service';
import { UserService } from './core/2/user.service';
import { debugLog, normalizingTel, sleep } from './core/2/util';
import { LogService } from './core/3/log.service';
import { UnifiedOrderService } from './core/4/unified-order.service';
import { RoomService } from './core/4/room.service';
import { ConfService } from './core/4/conf.service';
import { SiteService } from './core/4/site.service';
import { VersionService } from './core/4/version.service';
import { DirectLogService } from './core/5/direct-log.service';
import { ViewModelService } from './core/5/view-model.service';

import { LoadingService } from './shared/loading/loading.service';

interface AppViewModel {
  siteKey: string;
  siteName: string;
  sortKey: number;
  closed: { isClosed: boolean, msgClosed: string };
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  myVersion: string;
  latestVersion: string;

  currentUrl = '';
  currentSite: string;
  currentSiteClosedState = { isClosed: false, msgClosed: '' };
  appViewModel: AppViewModel[] = [];

  private versionSubscription: Subscription = null;
  private confAndSiteSubscription: Subscription = null;

  constructor(
    private platform: Platform,
    private confService: ConfService,
    private roomService: RoomService,
    private siteService: SiteService,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private unifiedMenuService: UnifiedMenuService,
    private viewModelService: ViewModelService,
    private authService: AuthService,
    private userService: UserService,
    private versionService: VersionService,
    private alertNoticeService: AlertNoticeService,
    private logService: LogService,
    private gtagService: GtagService,
    private localStorageService: LocalStorageService,
    private unifiedOrderService: UnifiedOrderService,
    private router: Router,
    private route: ActivatedRoute,
    private navController: NavController,
    private menu: MenuController,
    private loadingService: LoadingService,
    private directService: DirectLogService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready();
    this.statusBar.styleDefault();
    this.statusBar.backgroundColorByHexString('#ffffff');
    this.splashScreen.hide();
  }

  ngOnInit() {
    this.signIn();
    this.observeVersion();

    this.observeConfAndSiteForMenu();
    this.viewModelService.observeViewModel();
  }

  ngOnDestroy() {
    if (this.versionSubscription) {
      this.versionSubscription.unsubscribe();
    }
    if (this.confAndSiteSubscription) {
      this.confAndSiteSubscription.unsubscribe();
    }
  }

  public logMenuOpen() {
    this.directService.logDirect('info', '메뉴 - 열기', '지점');
  }

  public logMenuClose() {
    this.directService.logDirect('info', '메뉴 - 닫기', '지점');
  }

  public goToSite(siteKey: string) {
    this.navController.navigateRoot(siteKey);
    this.menu.close();
  }

  public logGhostkitchen() {
    this.directService.logDirect('click', '고스트키친?', '지점');
  }

  /**
   * Prod환경일 때 최신 버전이 아닌 경우 업데이트 팝업을 노출합니다.
   */
  private observeVersion() {
    this.myVersion = this.versionService.myVersion;
    this.latestVersion = this.versionService.latestVersion;

    this.versionSubscription = this.versionService.latestVersionSubject.subscribe(lastesVersion => {
      this.latestVersion = lastesVersion;
      if (environment.production && this.myVersion !== this.latestVersion) {
        this.alertNoticeService.blockNoticeAlertConfirm('새 버전이 있습니다.<br>업데이트합니다.', async () => {
          await this.logService.info(`업데이트 '확인'버튼 터치`);
          window.location.reload();
        });
      }
    });
  }

  /**
   * 지점이 닫혀있는 경우(conf/finger-direct) 앱 하단의 주문 버튼을 막고 알림 문구를 표시합니다.
   */
  private updateClosedState(currentSite: string) {
    if (this.appViewModel.length > 0) {
      const AppViewModelForCurrentSite = this.appViewModel.find(item => item.siteKey === currentSite);
      if (AppViewModelForCurrentSite) {
        this.currentSiteClosedState = AppViewModelForCurrentSite.closed ?? { isClosed: true, msgClosed: '준비중입니다.' };
      }
    }
  }

  private observeConfAndSiteForMenu() {
    const fingerDirectConfSubject = this.confService.lastestFingerDirectConfSubject;
    const siteSubject = this.siteService.latestSubject;

    // tslint:disable-next-line:max-line-length
    this.confAndSiteSubscription = combineLatest([fingerDirectConfSubject, siteSubject]).subscribe(([fingerDirectConf0, siteSubject0]) => {
      const enabledSites = Object.keys(fingerDirectConf0.closed);

      if (enabledSites.length > 0) {
        const siteDocs = siteSubject0;
        const closed = fingerDirectConf0.closed;
        this.currentSiteClosedState = closed[this.currentSite] ?? { isClosed: true, msgClosed: '준비중입니다.' };
        this.appViewModel = Object.values(siteDocs)
          .map(siteDoc => {
            return {
              siteKey: siteDoc._id,
              siteName: siteDoc.siteName.replace('고스트키친 ', ''),
              sortKey: siteDoc.sortKey,
              // undefined인 경우 ui에서 disabled 처리된다.
              closed: closed?.[siteDoc._id]
            };
          })
          .filter(homeViewModel => homeViewModel.sortKey > 0)
          .sort((a, b) => (b.sortKey - a.sortKey));
      }
    });
  }

  private observeRouter() {
    // https://stackoverflow.com/questions/41038970/how-to-determine-previous-page-url-in-angular
    // https://rxjs-dev.firebaseapp.com/api/operators/pairwise
    let prevUrl: string;
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        let currentSite = '';

        // 최초 'start'이벤트 이후 발생하는 route는 'goto'이벤트로 기록한다.
        if (prevUrl === undefined) {
          this.directService.logDirect('start', location.href);
          this.directService.logDirect('info', navigator.userAgent);
          prevUrl = location.href;
        } else {
          this.directService.logDirect('goto', location.href);
        }

        if (event instanceof NavigationEnd) {
          const url = event.urlAfterRedirects;
          const re = /^(\/[^/?]+)([^?]*)(?:\?(.+))?$/;
          const matches = re.exec(url);
          if (matches) {
            const rootUrl = matches[1].split('/')[1];
            currentSite = rootUrl !== 'my-order' ? rootUrl : undefined;

            // directService의 site는 undefiend인 경우도 반영한다.
            this.directService.site = currentSite;
            if (currentSite !== undefined && this.currentSite !== currentSite) {
              // url의 site가 바뀜에 따라 unifiedMenu도 다시 불러온다.
              this.unifiedMenuService.restartObservingForSite(currentSite);

              // url에 order값이 있는 경우 제거한다.
              const queryParams = this.route.snapshot.queryParams;
              if (queryParams.order) {
                const filteredParams = Object.fromEntries(Object.entries(queryParams).filter(([key, _]) => key !== 'order'));
                this.router.navigate([currentSite], { queryParams: filteredParams, replaceUrl: true });
              }
            }
          }

          this.currentUrl = url;
          this.currentSite = currentSite;
          this.updateClosedState(currentSite);
          // querystring을 제거한 url값만을 전달한다.
          const page = matches ? matches[1] + matches[2] : url;
          this.gtagService.page(page);
        }
      });
  }

  private async signIn() {
    try {
      await this.loadingService.presentLoading();
      const isSignIn = await this.authService.signinWithEmailAndPassword(environment.email, '810587B5-F3A8-4732-BF71-A9715A46D721');
      if (isSignIn) {
        this.versionService.observeVersion();
        this.userService.observe(environment.email);
        this.confService.observeFingerDirectConf();

        while (this.userService.user === undefined) {
          debugLog('Waiting for the first sync with Firestore user');
          await sleep(200);
        }

        this.roomService.observe();
        this.siteService.observe();
        this.loadConfirmState();

        const myVersion = this.versionService.myVersion;
        this.myVersion = myVersion;
        console.log(`myVersion = ${myVersion}`);
        this.logService.info(`로그인 성공. myVersion = ${myVersion}`, environment.email);

        this.directService.organization = this.userService.user.organization;
        this.observeRouter();

        // 앱 탈출시 로그를 남기기 위해 로그인 후에 탈출을 시도한다.
        this.escapeInApp();

        // 최초 로그인 후 redirect
        if (this.currentUrl.length > 0) {
          this.router.navigate([this.currentUrl]);
        }
      }
    } catch (error) {
      this.alertNoticeService.noticeAlert(error);
      this.logService.error(`로그인 실패 : ${error}`);
      this.router.navigate(['/redirect']);
    }

    this.loadingService.dismissLoading();
  }

  /******************************************************************
   * [인증정보 유효성 검사]
   * 1. loadConfirmState()
   * 2. checkUserTel()    전화번호 불러오기 및 유효성 검사.
   * 3. autoConfirm()     인증 정보 확인
   ******************************************************************/
  private async loadConfirmState() {
    const userTel = this.checkUserTel();
    if (userTel) {
      const confirmAuth = await this.autoConfirm();
      if (confirmAuth) {
        const now = new Date();
        const monthRange = 6;
        const endDate = fecha.format(new Date(now.setMonth(now.getMonth() - monthRange)), `YYYY-MM-DDTHH:mm:ss+0900`);
        this.unifiedOrderService.observeOrderFor(userTel, endDate);
      }
    }
  }

  private checkUserTel() {
    if (!this.localStorageService.isExist('userTel')) { return false; }

    const userTel = this.localStorageService.getValue<'userTel'>('userTel');
    const normalizedTel = normalizingTel(userTel);
    const match = normalizedTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
    if (match === null) {
      // 잘못된 형식의 전화번호가 저장되어있는 경우 삭제한다.
      this.localStorageService.removeItem('userTel');
    }
    return userTel;
  }

  private async autoConfirm() {
    if (!this.localStorageService.isExist('authSMS')) { return false; }
    if (!this.localStorageService.isExist('expires')) { return false; }
    const localExpires = this.localStorageService.getValue<'expires'>('expires');
    const localAuthSMS = this.localStorageService.getValue<'authSMS'>('authSMS');

    if (localAuthSMS.sessionId === undefined) {
      this.logService.error(`APP::local의 데이터가 undefined ${localAuthSMS}`);
      // 유요하지 않은 인증정보는 모두 삭제한다.
      this.localStorageService.removeItem('expires');
      this.localStorageService.removeItem('authSMS');
      return false;
    }

    const isExpired = new Date().getTime() > new Date(localExpires).getTime();
    if (!isExpired) {
      try {
        const response = await getConfirmAuth(localAuthSMS.sessionId, localAuthSMS.authCode);
        const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };

        if (result !== 'success') {
          this.logService.info(`재인증 실패 sessionId: ${localAuthSMS.sessionId}, authCode: ${localAuthSMS.authCode} : ${reason}`);
        } else {
          const newExpires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
          this.localStorageService.setItem('expires', newExpires);
          return true;
        }
      } catch (error) {
        this.logService.error(`APP::authConfirm에서 에러 발생 : ${error}`);
      }
    }
    // 유요하지 않은 인증정보는 모두 삭제한다.
    this.localStorageService.removeItem('expires');
    this.localStorageService.removeItem('authSMS');
    return false;
  }

  /** 인앱 브라우저 탈출 */
  private async escapeInApp() {
    const { userAgent } = navigator;
    const url = location.href;

    // tslint:disable-next-line:max-line-length
    if (!/mobile/i.test(userAgent) || !/inapp|KAKAOTALK|NAVER|Line\/|FB_IAB\/FB4A|FBAN\/FBIOS|Instagram|DaumDevice\/mobile|SamsungBrowser\/[^1]/i.test(userAgent)) {
      // 모바일 & InApp이 아니면 탈출하지 않는다
      return;
    }

    if (/Android/i.test(userAgent)) {
      await this.logService.info(`[안드로이드] 인앱 환경을 탈출합니다. userAgent: ${userAgent}`);
      location.href = `intent://${url.replace('https://', '')}#Intent;scheme=http;package=com.android.chrome;end`;
    }
    // else if (/Mac/i.test(agent)) {
    //   location.href=`ftp://ghostkitchen.net/home/ftponly/pub/bridge.html`;
    // }
  }
}
