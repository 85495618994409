/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import get from 'lodash/get';
import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { AngularFirestore } from '@angular/fire/firestore';

import { LogLevel } from '../../schema/1/schema-common';
import { Log } from '../../schema/2/schema-message';
import { LogOrderDoc } from '../../schema/3/schema';

import { instanceId } from '../1/common';
import { UtilService } from '../1/util.service';
import { UserService } from '../2/user.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private userService: UserService
  ) {
    console.log(`log instance ID = ${instanceId}`);
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에세 사용한다.
   */
  private send(level: LogLevel, message: string, email?: string) {
    const collectionPath = 'log';
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;
    const { userAgent } = navigator;

    const doc: Log = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,
      from: {
        // TODO: 현재는 테이크아웃과 같은 class를 사용한다.
        class: 'fingerFace',
        instanceNo: instanceId,
        account: email ? email : get(this.userService, ['user', 'email'], ''),
      },
      level,
      message: `${message} [userAgent: ${userAgent}]`
    };

    return this.db.doc<Log>(docRef).set(doc);
  }

  public debug(message: string, email?: string) {
    return this.send('debug', message, email);
  }
  public info(message: string, email?: string) {
    return this.send('info', message, email);
  }
  public warn(message: string, email?: string) {
    return this.send('warn', message, email);
  }
  public error(message: string, email?: string) {
    return this.send('error', message, email);
  }

  public withToastrError(message: string, title?: string, timeout?: number) {
    this.utilService.toastError(message, title, timeout);
    return this.send('error', `${title ? title + ': ' : ''} ${message}`);
  }

  public withToastrCatch(error: Error | string, reason?: string) {
    this.utilService.toastCatch(reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    return this.send('error', msg);
  }

  /**
   * @param email: 아직 userService에서 정보를 못 찾아오는 단계에서 사용한다.
   */
  public async logOrder(order: { _id: string; site: string; room: string; }, message: string, level?: LogLevel) {
    const collectionPath = 'logOrder';
    // message Id는 firestore가 제공하는 Id를 이용한다.
    const docRef = this.db.firestore.collection(collectionPath).doc();
    const docId = docRef.id;

    const organization = this.userService.organization;
    const doc: LogOrderDoc = {
      _id: docId,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp,

      organization,
      site: order.site ?? 'ERROR',
      room: order.room ?? 'ERROR',
      orderId: order._id ?? 'ERROR',

      instanceType: 'fingerFace',
      instanceNo: instanceId,
      account: this.userService?.user.email ?? '',

      // finger에서는 publicIP를 따로 추적하지 않고있다.
      // publicIP: this.addressService.publicAddress ?? '',
      publicIP: '',

      level: level ?? 'info',
      message
    };

    try {
      return await this.db.doc<LogOrderDoc>(docRef).set(doc);
    } catch (error) {
      this.withToastrCatch(error);
    }
  }

  /**
   * logRoom은 logOrder와 같은 컬렉션을 사용한다.
   */
  public logRoom(roomKey: string, message: string, level?: LogLevel) {
    const matches = roomKey.match(/^(gk-.+)-\d{2}$/);
    if (matches) {
      const site = matches[1];
      return this.logOrder({ _id: 'room', site, room: roomKey }, message, level);
    } else {
      return this.send('error', `${roomKey}에 대한 site를 유도할 수 없어서 logRoom 실패`);
    }
  }

  public logRoomWithToastWarn(roomKey: string, message: string, title?: string, timeout?: number) {
    this.utilService.toastWarning(message, title, timeout);
    return this.logRoom(roomKey, `${title ? title + ': ' : ''} ${message}`, 'warn');
  }

  public logRoomWithToastrError(roomKey: string, message: string, title?: string, timeout?: number) {
    this.utilService.toastError(message, title, timeout);
    return this.logRoom(roomKey, `${title ? title + ': ' : ''} ${message}`, 'error');
  }

  public logRoomWithToastrCatch(roomKey: string, error: Error | string, reason?: string) {
    this.utilService.toastCatch(error, reason);
    const msg = `${reason ? reason + ': ' : ''}${error}`;
    return this.logRoom(roomKey, msg, 'error');
  }
}
