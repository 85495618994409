/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: max-line-length
import fecha from 'fecha';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';

import { UnifiedOrderFood } from '../../schema/1/schema-common';
import { OrderStep, UserAddress } from '../../schema/1/schema-finger';
import { KiccPayType, KiccResult, KiccCardOrder } from '../../schema/1/schema-kicc-api';
import {
  UnifiedOrder,
  UnifiedOrderStatusCode,
  UnifiedOrderContextStatusCode,
  RoomDoc,
} from '../../schema/3/schema';

import { environment } from '../../../environments/environment';
import { UtilService } from '../../core/1/util.service';
import { checkIsDirect } from '../../core/1/common';
import { kiccResponseMap } from '../../core/1/string-map';
import { getRequestAuth, getConfirmAuth } from '../../core/1/sms-api';
import { AlertNoticeService } from '../../core/1/alert-notice.service';
import { LocalStorageService } from '../../core/1/local-storage.service';
import { KiccOrderService } from '../../core/1/kicc-order.service';
import { UnifiedMenuService } from '../../core/1/unified-menu.service';
import { normalizingTel } from '../../core/2/util';
import { LogService } from '../../core/3/log.service';
import { UnifiedOrderService } from '../../core/4/unified-order.service';
import { RoomService } from '../../core/4/room.service';
import { ConfService } from '../../core/4/conf.service';
import { SiteService } from '../../core/4/site.service';
import { ShoppingCartService } from '../../core/4/shopping-cart.service';
import { UserAddressService } from '../../core/5/user-address.service';
import { DirectLogService } from '../../core/5/direct-log.service';

import { LoadingService } from '../../shared/loading/loading.service';
import { ModalService } from '../../shared/modal/modal.service';
import { UnifiedOrderDocUI } from '../../schema/4/schema-ui';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit, OnDestroy, AfterViewInit {
  public isDirect = checkIsDirect();
  // KICC결제 모듈을 사용하기 위해 구성한 php서버의 페이지 Iframe
  @ViewChild('Iframe', { static: false }) private IframeRef: ElementRef;
  private iframeDomain = environment.paymentServer;
  public iframeUrl: SafeResourceUrl;

  public spPayType: KiccPayType = '11';
  public kiccResult: KiccResult;
  private kiccOrder: KiccCardOrder;
  private isResultResponsed = false;
  private spMallId = environment.kiccMallId;

  // 문자 인증
  public sessionId: string;
  public confirmAuth = false;
  public confirmUserTel: string;
  private authCode: string;
  private expires: string;

  public orderForm: FormGroup;
  private isOrderAvail: 'Y' | 'N' = 'Y';

  public currentSite: string;
  public room: RoomDoc;
  private unifiedOrderFoods: UnifiedOrderFood[] = [];

  // UI 표시용
  public step: OrderStep = 'order';
  public totalQty = 0;
  public userStateLoaded = false;
  public userAddress: UserAddress;
  public roomTelNo: string;
  public addressJibun = '';
  private availableLocalStorage = true;

  public unifiedOrder: Partial<UnifiedOrderDocUI> = {};
  private docRefId = '';

  private destroySignal = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private shoppingCartService: ShoppingCartService,
    private confService: ConfService,
    private roomService: RoomService,
    private siteService: SiteService,
    private logService: LogService,
    private unifiedMenuService: UnifiedMenuService,
    private unifiedOrderService: UnifiedOrderService,
    private kiccOrderService: KiccOrderService,
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService,
    private userAddressService: UserAddressService,
    private alertNoticeService: AlertNoticeService,
    private directLogService: DirectLogService,
    private utilService: UtilService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.currentSite = this.route.snapshot.paramMap.get('site');

    if (this.isDirect) {
      this.userAddressService.latestUserAddressSubject
        .pipe(takeUntil(this.destroySignal))
        .subscribe(userAddress => {
          const { address_sigungu = '', address_dong = '', address_jibun = '' } = userAddress;
          this.addressJibun = [address_sigungu, address_dong, address_jibun].join(' ');
          this.userAddress = userAddress;
          this.unifiedOrder = { ...this.unifiedOrder, ...this.userAddress };
          if (this.orderForm?.get('address_detail')) {
            this.orderForm.get('address_detail').setValue(this.userAddress.address_detail);
          }
        });
    }

    this.initForIframe();

    this.observeUnifiedMenuWithRoom();

    this.initModel();
    this.calcOrderAmount();
    this.initKiccOrder();
    this.buildForm();
    this.observeUserTel();

    this.availableLocalStorage = this.localStorageService.checkAvailable();
    this.loadLocalUserState();
  }

  ngOnDestroy() {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
    (window as any).ref = undefined;
  }

  ngAfterViewInit() {
    // 결제 진행 상태를 확인한다. (IFrame의 page 갱신이 일어날 때 마다 step을 체크한다.)
    this.IframeRef.nativeElement.onload = () => {
      if (this.step === 'finish' && !this.isResultResponsed) {
        this.kiccResult = this.IframeRef.nativeElement.contentWindow.getResult();
        this.isResultResponsed = true;
        if (this.kiccResult.res_cd === '0000') {
          this.finishOrder();
        }
      }
    };
  }

  public async presentAddressModal() {
    try {
      this.directLogService.logDirect('click', '주소 모달 호출', '주문페이지');
      await this.modalService.presentAddressModal();
    } catch (error) {
      this.directLogService.logDirect('error', `주소 모달 호출 실패: ${error}`, '주문페이지');
    }
  }

  /**
   * orderAmount계산 후
   * 주소지 위치와 업소의 설정을 기준으로 discount, deliveryTip을 계산한다.
   */
  private calcOrderAmount() {
    this.unifiedOrderFoods = this.shoppingCartService.unifiedOrderFoods;
    const orderAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
    this.totalQty = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);

    const shopNo = this.shoppingCartService.shopNo;

    const { lat, lon } = this.userAddress?.address_location ?? {};
    const { deliveryDistance, discount } = this.siteService.calcDeliveryDistanceAndDiscount(lat, lon);
    this.unifiedOrder.discount = discount;

    // 업소의 direct 설정에 따라 배달팁을 구한다.
    const unifiedMenu = this.unifiedMenuService.unifiedMenuForSite.find(menu => menu.shopNo === shopNo);
    if (unifiedMenu.direct) {
      const { distanceBasis, orderAmountBasis } = unifiedMenu.direct.deliveryTip;
      const matchedDistanceBasis = distanceBasis.sort((a, b) => a.to - b.to).find(v => deliveryDistance <= v.to) ?? distanceBasis[distanceBasis.length - 1];
      const matchedOrderAmountBasis = orderAmountBasis.sort((a, b) => b.from - a.from).find(v => orderAmount >= v.from) ?? orderAmountBasis[orderAmountBasis.length - 1];
      this.unifiedOrder.deliveryTip = (matchedDistanceBasis?.deliveryTip ?? 0) + (matchedOrderAmountBasis?.deliveryTip ?? 0);
    }

    this.unifiedOrder.orderAmount = orderAmount;
  }

  /******************************************************************
   * [Iframe handling]
   ******************************************************************/
  private initForIframe() {
    // 1. dev.kicc.ghostaurant.co의 SOP문제를 피하기 위해 도메인을 상위로 맞춤.
    document.domain = 'ghostaurant.co';
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeDomain + '/mobile/mobile/order.php');

    // 2. Child Dom에서 부모로 접근하기위해 현재 컴포넌트 포인터를 글로벌로 등록한다.
    (window as any).ref = { component: this };
  }

  /**
   * IFrame에서 호출 - 현재의 컴포넌트(parent)에 step 정보를 전달하기 위함
   */
  public updateStep(step: OrderStep) {
    this.step = step;
  }

  /**
   * IFrame에서 호출 - 결제창에서 정상결제가 아닌 응답(실패 또는 취소)의 처리
   * 이 경우 직접취소가 아니며 과정상 KiccNotification이 기록되지 않기 때문에 CANCELED가 아닌 BACK으로 상태를 변경한다.
   */
  public async kiccAlert(code: string, msg: string) {
    const msgForCode = kiccResponseMap[code] ?? msg;
    this.alertNoticeService.noticeAlert(msgForCode);
    const onCANCELED = fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900');

    // [주문상태 변경] STAGING -> BACK
    try {

      await this.unifiedOrderService.mergeOrder({
        _id: this.unifiedOrder._id,
        orderStatusCode: UnifiedOrderStatusCode.BACK,
        contextStatusCode: UnifiedOrderContextStatusCode.BACK,
        cancelReason: `[결제중단된 주문입니다.] 중단 사유: ${msgForCode}`,
        time: {
          onCANCELED
        }
      });

      this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `Easypay 결제 진행 중 중단되어 결제중단(BACK) 주문으로 상태를 변경했습니다. 중단 사유: ${msgForCode}`);
      this.directLogService.logDirect('info', `결제중단(BACK) 주문으로 상태 변경. 중단 사유: ${msgForCode}`, '주문페이지');
    } catch (err) {
      this.utilService.toastError(`${this.docRefId}/${err.message}`);
      this.logService.logOrder(
        this.unifiedOrder as UnifiedOrder,
        // tslint:disable-next-line:max-line-length
        `결제 중단에 따른 주문상태 변경(STAGING -> BACK)에 실패했습니다. 중단 사유: ${msgForCode}, 주문상태 변경 실패 Error: ${err.message}, kiccCode: ${code}, kiccMsg: ${msg}`,
        'error'
      );
      this.directLogService.logDirect('error', `결제 중단에 따른 주문상태 변경(STAGING -> BACK) 실패. 중단 사유: ${msgForCode}, error: ${err.message}, kiccCode: ${code}, kiccMsg: ${msg}`, '주문페이지');
    }
  }

  // IFrame에서 호출
  public presentLoading() {
    this.loadingService.presentLoading();
  }

  // IFrame에서 호출
  public dismissLoading() {
    this.loadingService.dismissLoading();
  }

  /******************************************************************
   * [Modeling]
   ******************************************************************/
  private initModel() {
    const shopNo = this.shoppingCartService.shopNo;
    const { organization, site, room, shopName } = this.getShopDetailFor(shopNo);

    this.unifiedOrder = {
      orderChannel: 'app',
      orderVendor: 'ghostkitchen',
      organization,
      site,
      room,
      shopName,
      shopNo,
      orderNo: '',
      instanceNo: '',

      deliveryType: this.isDirect ? 'DELIVERY' : 'TAKEOUT',
      orderDate: fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900'), // 나중에 최종 적으로 업데이트한다.
      orderStatusCode: UnifiedOrderStatusCode.STAGING,
      orderAmount: 0,
      deliveryTip: 0,
      deliveryMinutes: 0,
      paymentMethod: '선불',
      userTel: '',
      orderMsg: '',

      address_key: '',
      address_detail: '',
      address_sido: '',
      address_sigungu: '',
      address_dong: '',
      address_jibun: '',
      address_dongH: '',
      address_road: '',
      address_building_name: '',
      address_location: {
        lon: 0,
        lat: 0
      },
      vroong: {
        dest_sigungu: '',
        dest_legal_eupmyeondong: '',
        dest_admin_eupmyeondong: '',
        dest_ri: '',
        dest_beonji: '',
        dest_road: '',
        dest_building_number: '',
      },
      ...this.userAddress
    };
  }

  private initKiccOrder() {
    const init: KiccCardOrder = {
      /*--공통--*/
      sp_mall_id: this.spMallId,
      sp_order_no: '',
      sp_pay_type: this.spPayType,
      sp_currency: '00',
      sp_product_nm: '',
      sp_product_amt: '',
      sp_return_url: `${this.iframeDomain}/mobile/mobile/order_res_submit.php`,
      sp_user_phone1: '',
      sp_mall_nm: '고스트키친',
      sp_lang_flag: 'KOR',
      sp_charset: 'EUC-KR',
      sp_user_nm: '일반 고객',
      sp_product_type: '0',
      sp_window_type: 'iframe',
      sp_product_expr: '',
      sp_user_id: '',
      sp_memb_user_no: '',
      sp_user_mail: '',
      sp_user_phone2: '',
      sp_user_addr: '',
      sp_app_scheme: '',
      sp_top_window_url: window.location.href,

      /*--신용카드--*/
      sp_usedcard_code: '',
      sp_quota: '',
    };

    this.kiccOrder = init;
  }

  private buildForm() {
    this.orderForm = this.fb.group({
      userTel: [{ value: this.unifiedOrder.userTel, disabled: this.confirmAuth }, this.userTelValidator()],
      address_detail: [this.unifiedOrder.address_detail, this.addressValidator()],
      noDisposableItem: true,
      noSideDish: false,
      noFacing: false,
      orderMsg: this.unifiedOrder.orderMsg,
      authCode: '',
    }, { validators: this.formValidator() }); // cross field validation : https://angular.io/guide/form-validation#cross-field-validation
  }

  /**
   * userTel에 변화가 있으면 포맷을 자동 적용한다.
   */
  private observeUserTel() {
    const formControl = this.orderForm.get('userTel');

    formControl.valueChanges.forEach(value => {
      const normalizedTel = normalizingTel(value);
      if (value !== normalizedTel) {
        this.orderForm.get('userTel').setValue(normalizedTel);
      }
    });
  }

  private userTelValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      // this.orderForm은 this가 변할 경우에 undefined가 되는 경우가 있다.
      const userTel = control.value;

      if (userTel && userTel.length > 0) {
        const match = userTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
        if (match === null) {
          return { reason: '전화번호가 형식에 맞지 않습니다.' };
        }
      } else {
        return { reason: '전화번호가 필요합니다.' };
      }
    };
  }

  private addressValidator(): ValidatorFn {
    return (control: FormControl): ValidationErrors | null => {
      const addressDetail = control.value;

      if (this.isDirect) {
        if (!addressDetail || addressDetail.length < 1) {
          return { reason: '상세주소가 필요합니다.' };
        }
      }
    };
  }

  private formValidator(): ValidatorFn {
    return (control: FormGroup): ValidationErrors | null => {

      const orderForm = control;
      if (orderForm) {
        orderForm.get('userTel').updateValueAndValidity({ onlySelf: true });
      }

      return null;
    };
  }

  /**
   * UI 내용을 unifiedOrder에 반영한다.
   */
  private updateModel() {
    const { noDisposableItem, noSideDish, noFacing, userTel, address_detail, orderMsg } = this.orderForm.value;

    if (this.unifiedOrder.address_detail !== address_detail) {
      this.directLogService.logDirect('input', `상세주소: ${address_detail}`, '주문페이지');
    }

    if (orderMsg.length > 0) {
      this.directLogService.logDirect('input', `요청사항: ${orderMsg}`, '주문페이지');
    }

    let optionalMsg = noDisposableItem ? '(수저포크 X) ' : '(수저포크 O) ';
    optionalMsg += noSideDish ? '(김치, 단무지 X) ' : '';
    optionalMsg += noFacing ? '문앞에 두고 벨 눌러주세요. ' : '';

    this.unifiedOrder = {
      ...this.unifiedOrder,
      userTel: userTel.replace(/-/g, ''),
      address_detail,
      orderMsg: optionalMsg + orderMsg
    };
  }

  /******************************************************************
   * [KICC 결제]
   * 1. order()                       결제 인증 요청 - kiccCardOrderDoc 생성
   *    - deferredPayOrder()          후불결제
   *    - advancePayOrder()           선불결제
   *      - createOrder()             주문서 작성 - UnifiedOrderDoc 생성
   * 2. finishOrder()                 결제 완료 - UnifiedOrderDoc 상태변경
   ******************************************************************/
  public async order() {
    this.directLogService.logDirect('click', '결제하기', '주문페이지');
    // 주문전에 마지막으로 업소 & 지점상태를 확인한다.
    const isClosed = this.confService.fingerDirectConf.closed[this.currentSite]?.isClosed ?? true;
    if ((this.isOrderAvail === 'N') || isClosed) {
      this.alertNoticeService.noticeAlert('죄송합니다. 준비중인 업소입니다.');
      this.shoppingCartService.emptyCart();
      this.directLogService.logDirect('info', '준비중인 업소로 결제 취소', '주문페이지');
      return this.goHome();
    }

    // TODO: 로컬에 저장된 주소를 조작하여 결제하는 경우를 고려해본다.

    this.updateModel();

    // 전화번호 & 주소를 LocalStorage에 저장한다.
    if (this.availableLocalStorage) {
      this.saveLocalUserState();
    }

    // 후불과 선불결제를 구분한다.
    if (Number(this.spPayType) < 11) {
      this.deferredPayOrder();
    } else {
      this.advancePayOrder();
    }
  }

  /**
   * 후불결제 - 미사용
   */
  private async deferredPayOrder() {
    const result = await this.createOrder('deferred');
    if (!result) {
      return this.goHome();
    }

    this.directLogService.logDirect('popup', '후불결제 안내', '주문페이지');

    this.alertNoticeService.noticeAlertConfirm(
      `잠시 후 접수가 완료되면<br>예상 ${this.unifiedOrder.deliveryType === 'DELIVERY' ? '배달' : '조리'} 완료 시간을<br>문자로 알려드립니다.`,
      () => {
        this.directLogService.logDirect('click', '주문완료 확인', '후불결제 안내 팝업');
        this.goHome();
      },
      false
    );
  }

  /**
   * 선불결제
   */
  private async advancePayOrder() {
    const result = await this.createOrder('advance');
    if (!result) { return this.goHome(); }

    await this.loadingService.presentLoading();
    try {
      // 필수 항목 누락 여부 확인
      if (!this.kiccOrder.sp_order_no) {
        this.loadingService.dismissLoading();
        this.alertNoticeService.noticeAlert(`주문 실패 : 가맹점 주문번호가 비어있습니다.`);
        return;
      } else if (Number(this.kiccOrder.sp_product_amt) <= 0) {
        this.loadingService.dismissLoading();
        this.alertNoticeService.noticeAlert(`주문 실패 : 결제할 금액이 없습니다.`);
        return;
      }
      // 1. 결제 인증내용(kiccOrder) 기록
      await this.kiccOrderService.createKiccOrder(this.kiccOrder, this.unifiedOrder);

      // 2. Iframe으로 결제내용 전송
      this.IframeRef.nativeElement.contentWindow.submitOrder(this.kiccOrder);
      this.logService.logOrder(this.unifiedOrder as UnifiedOrder, 'Iframe으로 결제내용 전송', 'error');
      this.loadingService.dismissLoading();
    } catch (error) {
      await this.loadingService.dismissLoading();
      const { src } = this.IframeRef.nativeElement;

      if (this.isIOSInAppBrowser()) {
        this.directLogService.logDirect('popup', 'in-app 환경에서의 결제 에러로 Safari 접속안내 팝업', '주문페이지');
        this.alertNoticeService.noticeAlert('현재 환경(in-app)에서는 결제를 진행할 수 없습니다. Safari로 접속해주세요.');
        // tslint:disable-next-line:max-line-length
        this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `(IOS in App환경) KICC를 통한 선불결제 과정에서 에러 발생. error: ${error}, iframeSrc: ${src}, unifiedOrder: ${this.docRefId}`, 'error');
        this.directLogService.logDirect('error', `(IOS in App환경) KICC를 통한 선불결제 과정에서 에러 발생. Safari 접속 안내`, '주문페이지');
      } else {
        this.utilService.toastError('결제를 진행할 수 없습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
        // tslint:disable-next-line:max-line-length
        this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `KICC를 통한 선불결제 과정에서 에러 발생. error: ${error}, iframeSrc: ${src}, unifiedOrder: ${this.docRefId}`, 'error');
        this.directLogService.logDirect('error', `KICC를 통한 선불결제 과정에서 에러 발생. error: ${error}, iframeSrc: ${src}, unifiedOrder: ${this.docRefId}`, '주문페이지');
      }
    }
  }

  /**
   * 지불수단(선불, 후불)에 따라 주문정보를 생성한다.
   */
  private async createOrder(paymentMethod: 'advance' | 'deferred') {
    const docRefId = this.unifiedOrderService.getFingerDocId();
    if (!docRefId) {
      this.logService.logRoom(this.unifiedOrder.room, '주문 생성 실패: doc id 생성 실패', 'error');
      this.directLogService.logDirect('error', '주문 생성 실패 - doc id 생성 실패', '주문페이지');
      this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
      return false;
    }

    // 주문번호는 fingerId가 아닌 docRefId를 사용한다
    this.unifiedOrder.orderNo = docRefId;
    // 2021-06-08 배포 첫날 에러를 경험해서 일단 functions에 맡긴다.
    // tslint:disable-next-line:max-line-length
    // this.unifiedOrder.simpleNo = `${this.room.siteNo}-${String(await this.unifiedOrderService.getSimpleNo(this.unifiedOrder)).padStart(4, '0')}`;
    this.unifiedOrder.foods = this.unifiedOrderFoods;
    this.unifiedOrder.orderDate = fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900');

    // spread syntax로 userAddress의 주소를 넣는 과정에서 불필요하게 들어가는 필드를 제거한다.
    if (this.unifiedOrder?.deliveryDistance) {
      delete this.unifiedOrder.deliveryDistance;
    }

    if (paymentMethod === 'advance') {
      this.unifiedOrder.paymentMethod = '선불';

      const shortenFoodName = this.unifiedOrder.foods.length > 1
        ? this.unifiedOrder.foods[0].mergedName + `외 ${this.unifiedOrder.foods.length - 1}개`
        : this.unifiedOrder.foods[0].mergedName;

      /**
       * 줄인 후에도 KICC의 권장길이(40byte)를 넘어갈 여지가 있다면 '...'으로 요약한다.
       * (이미 줄여지는 내용이라 길이 계산을 타이트하게 하지 않았습니다.)
       * 상품명에 '&'문자를 금지한다.(KICC 규칙)
       */
      const spProductNm = (shortenFoodName.length >= 20
        ? (shortenFoodName.slice(0, 17) + '...')
        : shortenFoodName
      ).replace(/&/g, '');

      this.kiccOrder.sp_order_no = this.unifiedOrder.orderNo;
      this.kiccOrder.sp_user_phone1 = this.unifiedOrder.userTel;
      this.kiccOrder.sp_user_addr = this.unifiedOrder.address_detail;
      this.kiccOrder.sp_mall_nm = this.unifiedOrder.shopName;
      this.kiccOrder.sp_pay_type = this.spPayType;
      this.kiccOrder.sp_product_nm = spProductNm;
      this.kiccOrder.sp_product_amt = String(
        this.unifiedOrder.orderAmount + this.unifiedOrder.deliveryTip - this.unifiedOrder.discount
      );
    }

    if (paymentMethod === 'deferred') {
      this.unifiedOrder.paymentMethod = this.spPayType === '01' ? '후불카드' : '후불현금';
    }

    this.docRefId = docRefId;
    try {
      // 3. 주문내용(unifiedOrder) 기록
      const ret = await this.unifiedOrderService.createFingerOrder(this.unifiedOrder, this.docRefId);

      if (ret !== 'OK') {
        this.logService.logRoom(this.unifiedOrder.room, `주문 생성 실패: ${ret}`, 'error');
        this.directLogService.logDirect('error', `주문 생성 실패 - ${ret}`, '주문페이지');
        this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
      } else {
        this.unifiedOrder._id = `finger-${docRefId}`;
        this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `'결제하기' 버튼을 눌러서 결제 대기(STAGING) 주문을 생성했습니다.`);
        this.directLogService.logDirect('info', '결제 대기(STAGING) 주문 생성', '주문페이지');
      }
    } catch (err) {
      this.logService.logRoom(this.unifiedOrder.room, `알 수 없는 이유로 주문 생성 실패: ${this.docRefId}/${err}`, 'error');
      this.directLogService.logDirect('error', `주문 생성 실패 - docId: ${this.docRefId}, error: ${err}`, '주문페이지');
      this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
      return false;
    }

    return true;
  }

  /**
   * 결제가 정상 완료되면 호출되어 주문 상태를 NEW로 변환한다.
   */
  private async finishOrder() {
    try {
      await this.unifiedOrderService.mergeOrder({
        _id: this.unifiedOrder._id,
        orderStatusCode: UnifiedOrderStatusCode.NEW,
        contextStatusCode: UnifiedOrderContextStatusCode.NEW,
        // 결제 완료되어 실제 유효한 주문인 '신규주문'상태가 됐으니 orderDate를 갱신한다.
        orderDate: fecha.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+0900')
      });
      this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `Easypay를 통한 선불 결제가 완료되어 신규(NEW) 주문으로 상태를 변경했습니다.`);
      this.directLogService.logDirect('info', '신규(NEW) 주문으로 상태 변경', '주문페이지');

      // 정상처리시 쇼핑카트를 비워준다.
      this.shoppingCartService.emptyCart();
    } catch (error) {
      // 주문 결제가 완료되었으나 상태변경에 실패한 경우
      this.logService.logOrder(this.unifiedOrder as UnifiedOrder, `주문 상태 변경 실패(STAGING -> NEW). ${this.docRefId}/${error}`, 'error');
      this.directLogService.logDirect('error', `주문 상태 변경 실패(STAGING -> NEW) - docId: ${this.docRefId}, error: ${error}`, '주문페이지');
      this.utilService.toastError('주문 생성에 실패했습니다. 고스트키친 고객센터(1522-6385)로 연락주시면 해결해 드리겠습니다. 불편을 끼쳐 대단히 죄송합니다.');
    }
  }


  /******************************************************************
   * [전화번호 문자인증]
   * 1. sendAuthSMS()     인증 번호 문자 발송
   * 2. confirmSMSCode()  인증 번호 문자 확인
   ******************************************************************/
  public async sendAuthSMS() {
    // 인증 문자 확인 시 요청을 보낸 번호와 동일한지 비교하기 위해 저장한다.
    this.confirmUserTel = this.orderForm.get('userTel').value;
    const userTel = this.confirmUserTel.replace(/-/g, '');

    this.directLogService.logDirect('input', `전화번호: ${this.confirmUserTel}`, '주문페이지');
    this.directLogService.logDirect('click', this.sessionId ? '재전송' : '인증문자 전송', '주문페이지');

    try {
      await this.loadingService.presentLoading();
      const response = await getRequestAuth(userTel);

      const { sessionId } = await response.json() as { sessionId: string };
      this.sessionId = sessionId;
      this.directLogService.logDirect('info', `문자전송 성공 - ${this.confirmUserTel}`, '주문페이지');
    } catch (error) {
      this.utilService.toastError('인증 문자 발송에 실패했습니다. 잠시후 다시 시도해주세요.');
      this.directLogService.logDirect('error', `문자전송 실패 - ${this.confirmUserTel}, error: ${error}`, '주문페이지');
    }
    this.loadingService.dismissLoading();
  }

  public async confirmSMSCode() {
    const authCode = this.orderForm.get('authCode').value;
    const userTel = this.orderForm.get('userTel').value;

    this.directLogService.logDirect('input', `인증번호 입력: ${authCode}`, '주문페이지');
    this.directLogService.logDirect('click', '인증번호 확인', '주문페이지');

    try {
      await this.loadingService.presentLoading();
      const response = await getConfirmAuth(this.sessionId, authCode);

      const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };
      // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
      this.expires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
      this.confirmAuth = (result === 'success');

      if (reason !== null) {
        const msg = (reason === 'authCode mismatch') ? '잘못된 인증문자입니다.<br>다시 입력해주세요.' : reason;
        this.utilService.toastInfo(msg, undefined, 3000);
        this.directLogService.logDirect('info', `문자인증 실패 - ${userTel}, reason: ${reason}`, '주문페이지');
      } else {
        this.authCode = authCode;
        this.directLogService.logDirect('info', `문자인증 성공 - ${userTel}`, '주문페이지');
        this.orderForm.get('authCode').setValue('');
      }

    } catch (error) {
      this.directLogService.logDirect('error', `문자인증 실패 - ${userTel}, error: ${error}`, '주문페이지');
      this.utilService.toastError(`문자 인증에 실패하셨습니다. ${error}`);
    } finally {
      this.loadingService.dismissLoading();
    }
  }

  public logCheckBoxEvent(event: CustomEvent) {
    const target = event.target as HTMLIonCheckboxElement;
    const label = target.parentElement?.children?.[1]?.textContent;

    this.directLogService.logDirect('click', `${target.checked ? '🟢' : '❌'} - ${label}`);
  }

  /******************************************************************
   * [LocalStorage]
   ******************************************************************/
  private saveLocalUserState() {
    try {
      this.localStorageService.setItem('userTel', this.unifiedOrder.userTel);
      this.localStorageService.setItem('expires', this.expires);
      // 새로운 세션과 코드가 있는 경우만 최신화 한다.
      if (this.sessionId && this.authCode) {
        this.localStorageService.setItem('authSMS', {
          sessionId: this.sessionId,
          authCode: this.authCode
        });
      }

      // 배달고객인 경우 주소 저장
      if (this.isDirect) {
        this.userAddressService.setUserAddress({
          ...this.userAddress,
          address_detail: this.unifiedOrder.address_detail
        });
      }
    } catch (error) {
      this.directLogService.logDirect('error', `주문정보 저장 실패 - error: ${error}`, '주문페이지');
      this.utilService.toastError(`주문정보 저장 실패 : ${error}`);
    }
  }

  /**
   * 로컬에 저장된 전화번호와 인증 정보를 불러온 후 유효성을 확인한다.
   */
  private async loadLocalUserState() {
    await this.loadingService.presentLoading();
    // 1. 저장된 전화번호 유무 확인
    if (!this.localStorageService.isExist('userTel')) {
      return this.loadingService.dismissLoading();
    }

    try {
      const userTel = this.localStorageService.getValue<'userTel'>('userTel');
      // 저장된 전화번호의 형식을 체크한다.
      const normalizedTel = normalizingTel(userTel);
      const match = normalizedTel.match(/^(0[157]0[1-9]?-[1-9][0-9]{3,4}-[0-9]{4}|02-[2-9][0-9]{2,3}-[0-9]{4})$/);
      if (match === null) {
        this.localStorageService.removeItem('userTel');
        throw Error('LocalStorage에 잘못된 형식의 전화번호가 저장되어있습니다.');
      }
      this.orderForm.get('userTel').setValue(userTel);
      this.directLogService.logDirect('info', '로컬 정보 불러오기 완료', '주문페이지');

      // 2. 인증정보 확인
      if (userTel && this.localStorageService.isExist('expires')) {
        const localExpires = this.localStorageService.getValue<'expires'>('expires');
        const localAuthSMS = this.localStorageService.getValue<'authSMS'>('authSMS');

        // Safari는 +0900 형태에 대해서 에러를 리턴하기 때문에 parse후에 format을 한다.
        this.expires = fecha.format(fecha.parse(localExpires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');

        const now = new Date();
        const expiredDate = new Date(localExpires);
        const isExpried = now.getTime() > expiredDate.getTime();
        // 3. 인증 기간 만료 여부 확인
        if (!isExpried) {
          // 4. 저장된 인증 정보로 재인증 및 만료기간 연장(refresh)
          if (localAuthSMS && localAuthSMS.sessionId && localAuthSMS.authCode) {
            const response = await getConfirmAuth(localAuthSMS.sessionId, localAuthSMS.authCode);
            const { result, reason, expires } = await response.json() as { result: string, reason: string | null, expires: string };

            this.confirmAuth = (result === 'success');
            if (!this.confirmAuth) {
              this.localStorageService.removeItem('expires');
              this.localStorageService.removeItem('authSMS');
              this.directLogService.logDirect('error', `로컬 정보로 재인증 실패 - sessionId: ${localAuthSMS.sessionId}, authCode: ${localAuthSMS.authCode}, reason ${reason}`, '주문페이지');
            } else {
              this.sessionId = localAuthSMS.sessionId;
              this.expires = fecha.format(fecha.parse(expires, 'YYYY-MM-DDTHH:mm:ssZZ'), 'YYYY-MM-DDTHH:mm:ss+09:00');
              this.localStorageService.setItem('expires', this.expires);
              this.directLogService.logDirect('info', `로컬 정보로 재인증 완료 - ${normalizedTel}`, '주문페이지');
            }
          }
        } else {
          this.localStorageService.removeItem('expires');
          this.localStorageService.removeItem('authSMS');
          this.directLogService.logDirect('info', `로컬 정보의 기간 만료로 정보 초기화 - ${normalizedTel}`, '주문페이지');
        }
      }

      this.userStateLoaded = true;
    } catch (error) {
      this.directLogService.logDirect('error', `로컬 정보 불러오기 실패 - error: ${error}`, '주문페이지');
    }
    this.loadingService.dismissLoading();
  }

  public changeUserState() {
    this.directLogService.logDirect('click', '전화번호 수정하기', '주문완료');
    this.sessionId = undefined;
    this.confirmAuth = false;
    this.userStateLoaded = false;
  }

  public confirmOrderResult() {
    if (this.kiccResult?.res_cd === '0000') {
      this.directLogService.logDirect('click', '주문완료 확인', '주문완료');
    } else {
      this.directLogService.logDirect('click', `주문실패 확인 - reason: ${this.kiccResult?.res_msg}`, '주문실패');
    }
    this.goHome();
  }

  public logCallRoom() {
    this.directLogService.logDirect('click', `문의전화: ${this.roomTelNo}`, '주문페이지');
  }

  /******************************************************************
   * [Routing]
   ******************************************************************/
  public goHome() {
    this.router.navigate([this.currentSite], { replaceUrl: true });
  }

  /******************************************************************
   * [Observing]
   ******************************************************************/

  /**
   * unifiedMenu - 메뉴의 주문 가능여부(품절 등의 사유)를 모니터링한다.
   * roomDocs - 호실의 운영상태를 모니터링한다.
   */
  private observeUnifiedMenuWithRoom() {
    const shopNo = this.shoppingCartService.shopNo;
    const unifiedMenu = this.unifiedMenuService.latestUnifiedMenuForSiteSubject;
    const rooms = this.roomService.latestSubject;

    combineLatest([unifiedMenu, rooms])
      .pipe(takeUntil(this.destroySignal))
      .subscribe(([unifiedMenu0, rooms0]) => {
        const shopMenu = unifiedMenu0.find(menu => menu.shopNo === shopNo);
        const roomDoc = rooms0[shopMenu.room];
        const isLive = (
          // 업소의 '운영상태'가 true(운영)인가
          roomDoc.live
          // 테스트 업소인가
          && !roomDoc.virtual
          // room의 다이렉트 설정확인
          && roomDoc.direct?.enable === true
          // shop의 다이렉트 설정확인
          && shopMenu.direct?.enable === true);

        /** 2021.01.07 배민 운영 상태를 무시하는 '손가락 강제 오픈' 추가 */
        const orderAvailable = roomDoc.forceFingerOpen === true ? 'Y' : shopMenu.baeminShopInfo.Ord_Avail_Yn;
        this.isOrderAvail = isLive ? orderAvailable : 'N';

        this.room = roomDoc;
        this.roomTelNo = roomDoc.telNo ? normalizingTel(roomDoc.telNo) : '1522-6385';
      });
  }

  /**
   * 인앱환경 유무를 확인한다.
   */
  private isIOSInAppBrowser() {
    const { userAgent } = navigator;
    // tslint:disable-next-line: max-line-length
    if (!/mobile/i.test(userAgent) || !/inapp|KAKAOTALK|NAVER|Line\/|FB_IAB\/FB4A|FBAN\/FBIOS|Instagram|DaumDevice\/mobile|SamsungBrowser\/[^1]/i.test(userAgent)) {
      return false;
    }

    return /Mac/i.test(userAgent);
  }

  private getShopDetailFor(shopNo: string) {
    const shopDetail = this.shoppingCartService.shopDetail;
    if (shopDetail === undefined) {
      this.alertNoticeService.noticeAlert('업소 정보가 없습니다. 개발자에게 문의해주세요.');
      this.directLogService.logDirect('error', `업소 정보 오류 - shopNo: ${shopNo}`, '주문페이지');
      this.shoppingCartService.emptyCart();
      this.goHome();
      return;
    }

    const { organization, site, room, shopName } = shopDetail;
    return { organization, site, room, shopName };
  }
}
