/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: max-line-length
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { UnifiedOrderFood } from '../../schema/1/schema-common';

import { checkIsDirect } from '../../core/1/common';
import { AlertNoticeService } from '../../core/1/alert-notice.service';
import { UnifiedMenuService } from '../../core/1/unified-menu.service';
import { ShoppingCartService } from '../../core/4/shopping-cart.service';
import { UserAddressService } from '../../core/5/user-address.service';
import { DirectLogService } from '../../core/5/direct-log.service';

import { ModalService } from '../../shared/modal/modal.service';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  public isDirect = checkIsDirect();
  public disabled = false;
  public minOrderAmount = 0;

  public defaultHref: string;
  unifiedOrderFoods: UnifiedOrderFood[] = [];

  // UI 표시용
  totalQty = 0;
  orderAmount = 0;
  shopName: string;

  private shopNo: string;
  private subscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private unifiedMenuService: UnifiedMenuService,
    private alertNoticeService: AlertNoticeService,
    private shoppingCartService: ShoppingCartService,
    private directLogService: DirectLogService,
    private userAddressService: UserAddressService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.defaultHref = this.route.snapshot.paramMap.get('site');
    this.shopNo = this.shoppingCartService.shopNo;
    this.shopName = this.shoppingCartService.shopDetail.shopName;

    this.subscription = this.shoppingCartService.latestUnifiedOrderFoodsSubject.subscribe(unifiedOrderFoods => {
      this.unifiedOrderFoods = unifiedOrderFoods;
      this.orderAmount = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodOrdPrice, 0);
      this.totalQty = this.unifiedOrderFoods.reduce((sum, food) => sum + food.foodQty, 0);

      if (this.isDirect) {
        const unifiedMenu = this.unifiedMenuService.unifiedMenuForSite.find(menu => menu.shopNo === this.shopNo);
        // 업소의 direct 설정에 따라 '최소주문금액'을 구한다.
        if (unifiedMenu.direct) {
          const { orderAmountBasis } = unifiedMenu.direct.deliveryTip;
          orderAmountBasis.sort((a, b) => b.from - a.from);
          this.minOrderAmount = orderAmountBasis[orderAmountBasis.length - 1]?.from ?? 0;
          this.disabled = this.minOrderAmount ? this.orderAmount < this.minOrderAmount : true;
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  removeFood(index: number) {
    this.directLogService.logDirect('click', `메뉴삭제 - ${this.unifiedOrderFoods[index].foodName}`, '장바구니');
    this.shoppingCartService.removeFood(index);
  }

  increaseFoodQty(index: number) {
    this.directLogService.logDirect('click', `수량( + ) - ${this.unifiedOrderFoods[index].foodName}`, '장바구니');
    this.shoppingCartService.increaseFoodQty(index);
  }

  decreaseFoodQty(index: number) {
    this.directLogService.logDirect('click', `수량( - ) - ${this.unifiedOrderFoods[index].foodName}`, '장바구니');
    this.shoppingCartService.decreaseFoodQty(index);
  }

  goBackToShop() {
    this.directLogService.logDirect('click', '메뉴추가', '장바구니');
    if (this.shopNo) {
      this.router.navigate(['../', this.shopNo], { relativeTo: this.route });
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  public goOrder() {
    this.directLogService.logDirect('click', '주문하기', '장바구니');

    const userAddress = this.userAddressService.getUserAddress();
    if (this.isDirect && userAddress === undefined) {
      this.directLogService.logDirect('popup', '주소 입력 요청', '장바구니');

      this.alertNoticeService.blockNoticeAlertConfirm('거리에 따른 할인 적용을 위해<br>주소를 입력해주세요.', () => {
        this.directLogService.logDirect('click', '주소 모달 호출', ' 주소 입력 요청 팝업(업소)');

        this.modalService.presentAddressModal(undefined, () => {
          this.directLogService.logDirect('info', '주소 입력을 완료하여 주문페이지로 이동', '장바구니 - 주소 모달');
          this.router.navigate(['../', 'order'], { relativeTo: this.route });
        });
      });
      return;
    }

    this.router.navigate(['../', 'order'], { relativeTo: this.route });
  }
}
