/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, OnInit, Input } from '@angular/core';

import { KiccResult } from '../../schema/1/schema-kicc-api';
import { UnifiedOrder, SiteDoc } from '../../schema/3/schema';

import { payTypeMap } from '../../core/1/string-map';
import { normalizeTel } from '../../core/2/util';
import { SiteService } from '../../core/4/site.service';

@Component({
  selector: 'app-order-result',
  templateUrl: './order-result.component.html',
  styleUrls: ['./order-result.component.scss']
})
export class OrderResultComponent implements OnInit {
  @Input() public kiccResult: KiccResult;
  @Input() public unifiedOrder: UnifiedOrder;
  // @Input() private step: string;

  public isDelivery = false;
  public payType = '';
  public userTel: string;
  public site: SiteDoc;

  constructor(
    private siteService: SiteService
  ) { }

  ngOnInit() {
    const siteKey = this.unifiedOrder.site;
    this.site = this.siteService.sites[siteKey];
    this.isDelivery = this.unifiedOrder.deliveryType === 'DELIVERY';

    this.getPayType();
    this.userTel = normalizeTel(this.unifiedOrder.userTel);
  }

  getPayType() {
    this.payType = payTypeMap[this.kiccResult.pay_type] ?? '';
  }

  openDestMap() {
    const { lat, lng, siteName } = this.site;

    const placeName = encodeURI(`${siteName} (지하1층)`);
    const url = 'http://map.daum.net/link/map/' + placeName + `,${lat},${lng}`;

    // refer : http://apis.map.daum.net/web/guide/#bigmapurl
    // http://map.daum.net/?urlX=506255.0&urlY=1113450.0&name=%EB%85%BC%ED%98%84%EB%8F%99%2B170-14
    window.open(
      url,
      'centerscreen,alwaysRaised,noreferrer'
    );
  }
}
