/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from '@angular/router';

import { AlertNoticeService } from '../1/alert-notice.service';
import { SiteService } from '../4/site.service';
import { DirectLogService } from '../5/direct-log.service';
import { UserAddressService } from '../5/user-address.service';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - fingerDirectConf
 */
@Injectable({
  providedIn: 'root'
})
export class SiteGuardService implements CanActivate {
  constructor(
    private router: Router,
    private siteService: SiteService,
    private directLogService: DirectLogService,
    private alertNoticeService: AlertNoticeService,
    private userAddressService: UserAddressService
  ) { }

  promiseForInit(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const site = route.paramMap.get('site');
    const parsedUrl = this.router.parseUrl('');

    return new Promise<boolean | UrlTree>((resolve) => {
      // siteDoc에 존재하지 않는 업소는 접근을 막는다.
      if (this.siteService.sites[site] === undefined) {
        resolve(parsedUrl);
        this.directLogService.logDirect('error', `잘못된 지점 주소로 접근 - site: ${site}`, 'site-guard');
        this.alertNoticeService.noticeAlert('잘못된 주소입니다.');
      } else {
        if (this.siteService.currentSite !== site) {
          this.siteService.currentSite = site;
          this.userAddressService.getUserAddress();
        }
        resolve(true);
      }
    });
  }

  canActivate(route: ActivatedRouteSnapshot) {
    return this.promiseForInit(route);
  }
}
