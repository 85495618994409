/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UnifiedOrderFood, UnifiedOrderFoodOpt } from '../../schema/1/schema-common';
import { UnifiedMenuFood } from '../../schema/3/schema';
import { UnifiedMenuFoodOptUI } from '../../schema/4/schema-ui';

import { AlertNoticeService } from '../1/alert-notice.service';
import { UnifiedMenuService } from '../1/unified-menu.service';
import { postProcessFoods } from '../2/util';
import { LogService } from '../3/log.service';

interface ShopDetail {
  organization: string;
  site: string;
  room: string;
  shopName: string;
}
@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {
  public shopDetail: ShopDetail;
  public shopNo: string;
  public unifiedOrderFoods: UnifiedOrderFood[] = [];
  public latestUnifiedOrderFoodsSubject = new BehaviorSubject<UnifiedOrderFood[]>([]);

  constructor(
    private unifiedMenuService: UnifiedMenuService,
    private alertNoticeService: AlertNoticeService,
    private logService: LogService
  ) { }

  emptyCart() {
    this.unifiedOrderFoods = [];
    this.shopNo = undefined;

    this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
  }

  async addFood(food: UnifiedMenuFood, shopNo: string, foodQty: number, callBack: () => void) {
    if (this.shopNo && this.shopNo !== shopNo) {
      this.alertNoticeService.noticeAlertConfirm(
        '장바구니에는<br>같은 가게의 메뉴만 담을 수 있습니다.<br>이전에 담은 메뉴가 삭제됩니다.',
        () => {
          this.emptyCart();
          if (this.shopNo === undefined) {
            this.addFood(food, shopNo, foodQty, callBack);
          }
        }, true
      );
    } else {
      const unifiedMenu = this.unifiedMenuService.unifiedMenuForSite;
      const shop = unifiedMenu.find(menu => menu.shopNo === shopNo);
      this.shopNo = shopNo;

      if (shop) {
        const { organization, site, room, shopName } = shop;
        this.shopDetail = { organization, site, room, shopName };
      } else {
        this.logService.error(`addFood - [cartShopNo : ${shopNo}], [menuShopNo : ${shop.shopNo}]`);
        this.alertNoticeService.noticeAlert('메뉴추가에 실패했습니다. 개발자에게 문의해주세요.');

        callBack();
      }

      const foodOpts: UnifiedOrderFoodOpt[] = [];
      for (const foodOptGroup of food.foodOptGroups) {
        for (const foodOpt of foodOptGroup.foodOpts as UnifiedMenuFoodOptUI[]) {
          if (foodOpt._optQty > 0) {
            foodOpts.push({
              optGrpName: foodOptGroup.optGrpName,
              optName: foodOpt.optName,
              optPrice: foodOpt.optPrice,
              optQty: foodOpt._optQty
            });
          }
        }
      }

      const unifiedOrderFood: UnifiedOrderFood = {
        foodName: food.foodName,
        foodOpts,
        foodOrdPrice: foodOpts.reduce((acc, foodOpt) => acc + foodOpt.optPrice * foodOpt.optQty, 0) * foodQty,
        foodQty: foodQty ? foodQty : 1,
        mergedName: food.foodName
      };

      this.unifiedOrderFoods.push(unifiedOrderFood);
      this.unifiedOrderFoods = postProcessFoods(this.unifiedOrderFoods);

      this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);

      callBack();
    }
  }

  removeFood(index: number) {
    this.unifiedOrderFoods.splice(index, 1);

    if (this.unifiedOrderFoods.length < 1) {
      this.shopNo = undefined;
      this.shopDetail = undefined;
    }

    this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
  }

  increaseFoodQty(index: number) {
    const unifiedOrderFood = this.unifiedOrderFoods[index];

    unifiedOrderFood.foodQty++;
    // tslint:disable-next-line:max-line-length
    unifiedOrderFood.foodOrdPrice = unifiedOrderFood.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * unifiedOrderFood.foodQty;

    this.unifiedOrderFoods[index] = unifiedOrderFood;
    this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
  }

  decreaseFoodQty(index: number) {
    const unifiedOrderFood = this.unifiedOrderFoods[index];

    if (unifiedOrderFood.foodQty < 2) {
      this.removeFood(index);
    } else {
      unifiedOrderFood.foodQty--;
      // tslint:disable-next-line:max-line-length
      unifiedOrderFood.foodOrdPrice = unifiedOrderFood.foodOpts.reduce((sum, foodOpt) => sum + foodOpt.optQty * foodOpt.optPrice, 0) * unifiedOrderFood.foodQty;

      this.unifiedOrderFoods[index] = unifiedOrderFood;
      this.latestUnifiedOrderFoodsSubject.next(this.unifiedOrderFoods);
    }
  }
}
