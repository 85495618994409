/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: max-line-length
import cloneDeep from 'lodash/cloneDeep';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IonContent } from '@ionic/angular';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChildren, QueryList, ElementRef, Renderer2, ViewChild } from '@angular/core';

import { UnifiedMenuDoc, UnifiedMenuFood } from '../../schema/3/schema';

import { checkIsDirect } from '../../core/1/common';
import { UtilService } from '../../core/1/util.service';
import { AlertNoticeService } from '../../core/1/alert-notice.service';
import { UnifiedMenuService } from '../../core/1/unified-menu.service';
import { normalizingTel, sleep } from '../../core/2/util';
import { LogService } from '../../core/3/log.service';
import { RoomService } from '../../core/4/room.service';
import { ShoppingCartService } from '../../core/4/shopping-cart.service';
import { DirectLogService } from '../../core/5/direct-log.service';

import { LoadingService } from '../../shared/loading/loading.service';

interface ShopViewModel {
  roomNo: number;
  shopName: string;
  menus: FoodGrp[];
  direct?: UnifiedMenuDoc['direct'];
  telNo?: string;
}
interface FoodGrp {
  foodGrpName?: string;
  foods: MenuFood[];
}
interface MenuFood extends UnifiedMenuFood {
  foodNo: number;
}
@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit, OnDestroy, AfterViewInit {
  public isDirect = checkIsDirect();
  // 메뉴보기에서 넘어온 경우 해당 메뉴를 표시하기 위해 사용한다.
  @ViewChildren('foodRef') foodRefs: QueryList<ElementRef>;
  @ViewChild('IonContent') ionContent: IonContent;
  public currentSite: string;

  // UI 표시용
  public shopViewModel: ShopViewModel;
  public totalQty = 0;
  public orderAmount = 0;
  public foodNo: number;
  public minOrderAmount: number;
  public minDeliveryTip: number;
  public maxDeliveryTip: number;

  private currentShop: string;
  /** logRoom에 사용한다. */
  private roomKey: string;
  private destroySignal = new Subject<void>();

  constructor(
    private unifiedMenuService: UnifiedMenuService,
    private shoppingCartService: ShoppingCartService,
    private utilService: UtilService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private renderer: Renderer2,
    private logService: LogService,
    private alertNoticeService: AlertNoticeService,
    private roomService: RoomService,
    private directLogService: DirectLogService
  ) { }

  ngOnInit() {
    this.currentSite = this.route.snapshot.paramMap.get('site');
    this.currentShop = this.route.snapshot.paramMap.get('shop');

    this.observeUnifiedMenu();
    this.observeShoppingCart();

    // 메인에서 특정 메뉴를 선택해서 들어온 경우 해당 메뉴로 스크롤을 이동시켜준다.
    this.foodNo = (this.location.getState() as { foodNo: number }).foodNo;
  }

  ngOnDestroy() {
    this.destroySignal.next();
    this.destroySignal.unsubscribe();
  }

  async ngAfterViewInit() {
    if (this.foodNo !== undefined) {
      const destElement = this.foodRefs.find(ref => ref.nativeElement.className === 'auto-scroll-target');

      // 최초 offsetTop값이 설정될 수 있도록 실행 순서를 의도적으로 미룬다.
      await sleep(100);
      const offsetTop = destElement.nativeElement.offsetTop;
      // offset값을 기준으로 애니메이션 타이밍을 정한다. 단 최대 딜레이 700ms
      await sleep(Math.min(offsetTop, 700));
      this.ionContent.scrollToPoint(0, (offsetTop - 100), 700);

      // blink-animation
      await sleep(500);
      this.renderer.addClass(destElement.nativeElement, 'blink-animation');
      await sleep(1500);
      this.renderer.removeClass(destElement.nativeElement, 'blink-animation');
    }
  }

  public goToMenuForm(menuIndex: number, foodIndex: number) {
    try {
      const menu = this.shopViewModel.menus[menuIndex];
      const food = menu.foods[foodIndex];
      const price = Intl.NumberFormat().format(food.foodOptGroups[0].foodOpts[0].optPrice);
      this.directLogService.logDirect('click', `${menu.foodGrpName} - ${food.foodName}(${price}원)`, '업소');
    } catch (error) {
      this.logService.logRoom(this.roomKey, '메뉴 선택 로그를 남기는 과정에서 예외 발생', 'error');
    }

    this.router.navigate([`${menuIndex}-${foodIndex}`], { relativeTo: this.route });
  }

  public goOrder() {
    this.directLogService.logDirect('click', '주문하기', '업소');
    this.router.navigate(['../cart'], { relativeTo: this.route });
  }

  public logCallRoom() {
    this.directLogService.logDirect('click', `문의전화: ${this.shopViewModel.telNo}`, '업소');
  }

  /**
   * 배달팁 정책을 자세히 알린다.
   * alertNotice의 css는 global.scss파일에서 지정한다.
   */
  public presentDeliveryTipAlert() {
    const formatter = new Intl.NumberFormat();
    const { distanceBasis, orderAmountBasis } = this.shopViewModel.direct.deliveryTip;

    distanceBasis.sort((a, b) => a.to - b.to);

    let toOrderAmount: number;
    const orderAmountDeliveryTip = orderAmountBasis.sort((a, b) => b.from - a.from).map(i => {
      const text = toOrderAmount
        ? `<div class="tip-item"><span>${formatter.format(i.from)}원~${formatter.format(toOrderAmount)}원 미만</span><span>${formatter.format(i.deliveryTip)}원</span></div>`
        : `<div class="tip-item"><span>${formatter.format(i.from)}원 이상</span><span>${formatter.format(i.deliveryTip)}원</span></div>`;
      toOrderAmount = i.from;
      return text;
    }).join('');

    let toDeliveryDistance: number;
    const deliveryDistanceDeliveryTip = distanceBasis.sort((a, b) => a.to - b.to).map(i => {
      const text = toDeliveryDistance
        ? `<div class="tip-item"><span>${formatter.format(toDeliveryDistance)}m ~ ${formatter.format(i.to)}m 이하</span><span>${formatter.format(i.deliveryTip)}원</span></div>`
        : `<div class="tip-item"><span>${formatter.format(i.to)}m 이하</span><span>${formatter.format(i.deliveryTip)}원</span></div>`;
      toDeliveryDistance = i.to;
      return text;
    }).join('');

    const notice = `<div class="delivery-tip">
        <div class="tip-header"><b>주문금액</b><b>배달팁</b></div>
        ${orderAmountDeliveryTip}
        <div class="tip-info">배달거리에 따른 추가요금</div>
        <div class="tip-header"><b>거리</b><b>배달팁</b></div>
        ${deliveryDistanceDeliveryTip}
      </div>`;

    this.directLogService.logDirect('popup', '배달팁 안내', '업소');
    this.alertNoticeService.noticeAlert(notice, '배달팁 안내');
  }

  private observeUnifiedMenu() {
    this.unifiedMenuService.latestUnifiedMenuForSiteSubject
      .pipe(takeUntil(this.destroySignal))
      .subscribe(async (unifiedMenuDocs) => {
        if (unifiedMenuDocs.length > 0) {
          const shop: UnifiedMenuDoc = unifiedMenuDocs.find(menu => menu.shopNo === this.currentShop);
          if (shop) {
            const unifiedMenu: UnifiedMenuDoc = cloneDeep(shop);
            this.roomKey = unifiedMenu.room;

            this.shopViewModel = { roomNo: 0, shopName: '', menus: [] };
            this.shopViewModel.roomNo = Number(unifiedMenu.room.split('-')[2]);
            this.shopViewModel.shopName = unifiedMenu.shopName;
            this.shopViewModel.menus = unifiedMenu.menus.map((menu, menuIndex) => {
              const menuFood: MenuFood[] = menu.foods.map((food, foodIndex) => {
                // tslint:disable-next-line:max-line-length
                const ktStaticPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/toe-menu-images';
                let imageUrl: string;
                if (food.imageUrl) {
                  const partialUrl = food.imageUrl.split('.com');
                  partialUrl[0] = ktStaticPrefix;
                  imageUrl = partialUrl.join('');
                }
                return {
                  ...food,
                  imageUrl,
                  foodNo: this.createFoodNo(menuIndex, foodIndex)
                };
              });
              return {
                foodGrpName: menu.foodGrpName,
                foods: menuFood
              };
            });

            const telNo = this.roomService.rooms[unifiedMenu.room]?.telNo;
            this.shopViewModel.telNo = telNo ? normalizingTel(telNo) : undefined;

            if (unifiedMenu.direct) {
              this.shopViewModel.direct = unifiedMenu.direct;

              const { orderAmountBasis, distanceBasis } = unifiedMenu.direct.deliveryTip;
              const orderAmountBasisDeliveryTips = orderAmountBasis.map(o => o.deliveryTip);
              const distanceBasisDeliveryTips = distanceBasis.map(d => d.deliveryTip);

              this.minOrderAmount = orderAmountBasis.sort((a, b) => a.from - b.from)[0]?.from;
              if (this.minOrderAmount === undefined) {
                this.logService.logRoomWithToastrError(this.roomKey, `가게(${this.shopViewModel.shopName})의 주문금액별 배달팁 설정이 비어있습니다.`);
              }

              this.minDeliveryTip = Math.min(...distanceBasisDeliveryTips) + Math.min(...orderAmountBasisDeliveryTips);
              this.maxDeliveryTip = Math.max(...distanceBasisDeliveryTips) + Math.max(...orderAmountBasisDeliveryTips);
            }
          } else {
            this.utilService.toastWarning('존재하지 않는 URL입니다.');
            this.router.navigateByUrl(this.currentSite);
          }
          this.loadingService.dismissLoading();
        } else {
          await this.loadingService.presentLoading();
        }
      });
  }

  private observeShoppingCart() {
    // Menu 페이지로 이동 후에도 이 컴포넌트는 존재하기 때문에 Back으로 돌아온 경우를 대비해 구독한다.
    this.shoppingCartService.latestUnifiedOrderFoodsSubject
      .pipe(takeUntil(this.destroySignal))
      .subscribe(unifiedOrderFoods0 => {
        this.orderAmount = unifiedOrderFoods0.reduce((sum, food) => sum + food.foodOrdPrice, 0);
        this.totalQty = unifiedOrderFoods0.reduce((sum, food) => sum + food.foodQty, 0);
      });
  }

  private createFoodNo(menuIndex: number, foodIndex: number) {
    const prefix = String(menuIndex).padStart(2, '0');
    const suffix = String(foodIndex).padStart(2, '0');
    return Number(prefix + suffix);
  }
}
