/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { UserAddress } from '../../schema/1/schema-finger';

import { LocalStorageService } from '../1/local-storage.service';
import { SiteService } from '../4/site.service';

@Injectable({
  providedIn: 'root'
})
export class UserAddressService {
  public userAddress: UserAddress;
  public latestUserAddressSubject = new BehaviorSubject<UserAddress>(undefined);

  constructor(
    private localStorageService: LocalStorageService,
    private siteService: SiteService
  ) { }

  /**
   * 혹시라도 localStorage 사용이 불가능한 경우를 고려하여
   * localService의 내용이 없는 경우 userAddress로 대체한다.
   */
  public getUserAddress() {
    if (!this.localStorageService.isExist('userAddress')) {
      return this.userAddress;
    }

    const userAddress = this.localStorageService.getValue<'userAddress'>('userAddress');
    if (userAddress) {
      const { deliveryDistance, discount } = this.siteService.calcDeliveryDistanceAndDiscount(
        userAddress.address_location.lat,
        userAddress.address_location.lon
      );
      this.userAddress = { ...userAddress, deliveryDistance, discount };
      this.latestUserAddressSubject.next(this.userAddress);
    }

    return this.userAddress;
  }

  /**
   * 사용자의 주소를 로컬에 저장(또는 수정)한다.
   * 주소가 변경됨에 따라서 할인금액과 배달거리를 다시 계산한다.
   */
  public setUserAddress(userAddress: UserAddress) {
    // 주소가 변경되면 할인금액 표시를 보여준다.
    if (this.userAddress?.address_road !== userAddress?.address_road) {
      this.localStorageService.removeItem('confirmDiscount');
    }

    this.userAddress = userAddress;

    const { deliveryDistance, discount } = this.siteService.calcDeliveryDistanceAndDiscount(
      userAddress.address_location.lat,
      userAddress.address_location.lon
    );
    this.userAddress.deliveryDistance = deliveryDistance;
    this.userAddress.discount = discount;
    this.latestUserAddressSubject.next(this.userAddress);
    this.localStorageService.setItem('userAddress', this.userAddress);
  }

  public initUserAddress() {
    this.localStorageService.removeItem('userAddress');
    this.userAddress = undefined;
    this.latestUserAddressSubject.next(this.userAddress);
  }
}
