/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { NavController } from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { checkIsDirect } from '../../core/1/common';
import { trimOrganization } from '../../core/2/util';
import { SiteService } from '../../core/4/site.service';
import { ConfService } from '../../core/4/conf.service';

interface HomeViewModel {
  _id: string;
  siteName: string;
  sortKey: number;
  thumbnail: string;
  closed: boolean;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public isDirect = checkIsDirect();
  public homeViewModel: HomeViewModel[] = [];

  private siteSubscription: Subscription;

  constructor(
    private siteService: SiteService,
    private confService: ConfService,
    private navController: NavController,
  ) { }

  ngOnInit() {
    this.observeSite();
  }

  ngOnDestroy() {
    this.siteSubscription?.unsubscribe();
  }

  observeSite() {
    const imageUrlPrefix = 'https://ssproxy.ucloudbiz.olleh.com/v1/AUTH_d722d13e-44ea-44ad-8c9b-2f5763ce3d40/ghostkitchen/site/';

    this.siteService.latestSubject.subscribe(siteDocs => {
      this.homeViewModel = Object.values(siteDocs).map(siteDoc => {
        const closedState = this.confService.fingerDirectConf.closed[siteDoc._id];
        return {
          _id: siteDoc._id,
          siteName: trimOrganization(siteDoc.siteName),
          sortKey: siteDoc.sortKey,
          thumbnail: `${imageUrlPrefix}${siteDoc._id}-front.jpg`,
          // undefined인 경우 ui에서 disabled 처리된다.
          closed: closedState?.isClosed,
        };
      })
        .filter(homeViewModel => homeViewModel.sortKey > 0)
        .sort((a, b) => (b.sortKey - a.sortKey));
    });
  }

  goSite(id: string) {
    this.navController.navigateRoot(`/${id}`);
  }
}
