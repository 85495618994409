/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
// tslint:disable: max-line-length
import { Component, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormControl, FormGroup } from '@angular/forms';

import { UserAddress } from '../../../schema/1/schema-finger';
import { DaumPostcodeResponse } from '../../../schema/1/schema-daum-postcode-api';
import { AugmentedAddress } from '../../../schema/1/schema-common';
import { CallInputAugmentAddress, CallOutputAugmentAddress } from '../../../schema/4/schema-functions-call';

import { AlertNoticeService } from '../../../core/1/alert-notice.service';
import { UnifiedMenuService } from '../../../core/1/unified-menu.service';
import { SiteService } from '../../../core/4/site.service';
import { DirectLogService } from '../../../core/5/direct-log.service';
import { UserAddressService } from '../../../core/5/user-address.service';

import { LoadingService } from '../../loading/loading.service';

const daum = (window as any).daum;

@Component({
  selector: 'app-address-search-modal',
  templateUrl: './address-search-modal.component.html',
  styleUrls: ['./address-search-modal.component.scss']
})
export class AddressSearchModalComponent implements OnInit, AfterViewInit {
  @Input() public userAddress: UserAddress;
  @Input() public callback: () => void;
  @ViewChild('Postcode') postcodeElement: ElementRef<HTMLDivElement>;
  public title = {
    setting: '주소 설정',
    search: '주소 검색',
    input: '상세주소 입력'
  };
  public response: AugmentedAddress;
  public searchKeyword: string;
  public addressForm = new FormGroup({
    address_detail: new FormControl('')
  });
  public addressRoad = '';
  public addressJibun = '';

  public addressInputStep: 'setting' | 'search' | 'input' = 'search';
  public editAddressDetail = false;

  private currentSite: string;

  constructor(
    private fns: AngularFireFunctions,
    private modalController: ModalController,
    private changeDetector: ChangeDetectorRef,
    private siteSerivce: SiteService,
    private loadingService: LoadingService,
    private unifiedMenuService: UnifiedMenuService,
    private alertNoticeService: AlertNoticeService,
    private directLogService: DirectLogService,
    private userAddressService: UserAddressService,
    private siteService: SiteService
  ) { }

  ngOnInit() {
    this.addressInputStep = this.userAddress !== undefined ? 'setting' : 'search';
  }

  ngAfterViewInit() {
    if (this.userAddress === undefined) {
      this.daumPostcodeLoad();
    }
    this.currentSite = this.unifiedMenuService.currentSite;
  }

  public dismissModal() {
    this.directLogService.logDirect('click', '주소 모달 닫기', '주소 모달');
    this.modalController.dismiss();
  }

  public onSubmit() {
    const { address_detail } = this.addressForm.value;

    // 수정인 경우 현재 주소를, 신규 입력인 경우 augmentAddress의 응답을 저장한다.
    if (this.editAddressDetail) {
      const oldAddressDetail = this.userAddress.address_detail;
      this.userAddressService.setUserAddress({
        ...this.userAddress,
        address_detail
      });
      this.directLogService.logDirect('click', `주소 수정 완료 - ${oldAddressDetail} -> ${address_detail}`, '주소 모달');

      // 수정이 완료되면 설정 모드로 변경한다.
      this.addressInputStep = 'setting';
      this.editAddressDetail = false;
      this.userAddress = this.userAddressService.getUserAddress();
    } else {
      const { deliveryDistance, discount } = this.siteService.calcDeliveryDistanceAndDiscount(this.response.location?.lat, this.response.location?.lon);
      this.userAddressService.setUserAddress({
        address_key: this.response.key,
        address_dongH: this.response.dongH,
        address_road: this.response.road,
        address_building_name: this.response.building_name,
        address_location: this.response.location,
        address_sido: this.response.sido,
        address_sigungu: this.response.sigungu,
        address_dong: this.response.dong,
        address_jibun: this.response.jibun,
        address_detail,
        vroong: this.response.vroong,
        deliveryDistance,
        discount
      });
      this.directLogService.logDirect('click', `주소 입력 완료 - ${this.response.road} ${address_detail}`, '주소 모달');
      this.modalController.dismiss();
    }

    this.callback?.();
  }

  public backToSetting() {
    if (this.editAddressDetail) {
      this.directLogService.logDirect('click', '뒤로가기(상세주소 입력 -> 주소 설정)', '주소 모달');
    } else {
      this.directLogService.logDirect('click', '뒤로가기(주소 검색 -> 주소 설정)', '주소 모달');
    }

    this.addressInputStep = 'setting';
    this.addressForm.get('address_detail').setValue('');
    this.editAddressDetail = false;
  }

  public backToSearch() {
    this.directLogService.logDirect('click', '뒤로가기(상세주소 입력 -> 주소 검색)', '주소 모달');
    this.daumPostcodeLoad();
  }

  public goToSearch() {
    this.directLogService.logDirect('click', '새로 입력', '주소 모달');
    this.daumPostcodeLoad();
  }

  public initUserAddress() {
    this.alertNoticeService.noticeAlertConfirm('주소를 삭제하시겠습니까?', () => {
      this.directLogService.logDirect('click', '현재 주소 삭제', '주소 모달');
      this.userAddressService.initUserAddress();
      this.userAddress = this.userAddressService.getUserAddress();
      this.daumPostcodeLoad();
    }, true);
  }

  public onEditAddressDetail() {
    this.directLogService.logDirect('click', '현재 주소 수정', '주소 모달');

    this.response = undefined;
    this.editAddressDetail = true;
    this.addressRoad = this.userAddress.address_road;
    this.addressJibun = [this.userAddress.address_sigungu, this.userAddress.address_dong, this.userAddress.address_jibun].join(' ');
    this.addressInputStep = 'input';
  }

  /**
   * 외부 frame인 daumPoscodeLoad를 거쳐 response를 받아온 후 이어지는 이벤트를 앵귤러가 빠르게 감지하지 못하는 문제가 발생했다.
   * 일단은 임시로 detectChanges을 적용한다.
   */
  public detectChanges() {
    this.changeDetector.detectChanges();
  }

  private daumPostcodeLoad() {
    this.response = undefined;
    this.addressInputStep = 'search';
    this.addressRoad = '';
    this.addressJibun = '';

    const postcode = new daum.Postcode({
      onsearch: (data: { q: string, count: number }) => {
        this.searchKeyword = data.q;
        this.directLogService.logDirect('input', `${this.searchKeyword}`, '주소 모달');
      },
      oncomplete: async (data: DaumPostcodeResponse) => {
        try {
          await this.loadingService.presentLoading();

          const response = await this.augmentAddress(data.roadAddress, data.jibunAddress);
          this.addressInputStep = 'input';
          this.addressForm.get('address_detail').setValue('');

          const formatter = Intl.NumberFormat();
          const { sigungu = '', dong = '', jibun = '' } = response;
          this.addressRoad = response.road;
          this.addressJibun = [sigungu, dong, jibun].join(' ');

          const { lat, lon } = response.location;
          const { maxDeliveryDistance } = this.siteSerivce.sites[this.currentSite].direct;
          const { deliveryDistance } = this.siteSerivce.calcDeliveryDistanceAndDiscount(lat, lon);
          this.directLogService.logDirect('info', `주소 검색 완료 - ${response.road}, 거리: ${formatter.format(deliveryDistance)}m`, '주소 모달');
          this.response = response;

          if (deliveryDistance > maxDeliveryDistance) {
            // tslint:disable-next-line:max-line-length
            this.alertNoticeService.noticeAlert(`고객님의 위치(${formatter.format(deliveryDistance)}m)는 배달가능 거리(${formatter.format(maxDeliveryDistance)}m)를 초과하여 주문이 불가합니다.`);
            this.directLogService.logDirect('info', `배달 거리(${formatter.format(maxDeliveryDistance)}m) 초과로 주소 입력 불가 - 거리: ${formatter.format(deliveryDistance)}m`, '주소 모달');
            this.daumPostcodeLoad();
          }

          this.changeDetector.detectChanges();
          this.loadingService.dismissLoading();
        } catch (error) {
          this.loadingService.dismissLoading();
          this.directLogService.logDirect('error', `augmentAddress: ${error.message}`, '주소 모달');
          this.alertNoticeService.noticeAlert(`주소 정보 획득에 실패했습니다. 내용을 확인해주세요. 오류: ${error.message}`);
          this.daumPostcodeLoad();
        }
      },
      width: '100%',
      height: '100%',
      autoClose: false
    });

    // 이전에 입력한 검색어가 있는 경우 유지시켜준다.
    postcode.embed(this.postcodeElement.nativeElement, this.searchKeyword ? { q: this.searchKeyword } : undefined);

    this.changeDetector.detectChanges();
  }

  private async augmentAddress(rawAddressRoad: string, rawAddressJibun: string) {
    try {
      let resMessage: CallOutputAugmentAddress;
      const requestAugmentAddress = this.fns.httpsCallable<CallInputAugmentAddress, CallOutputAugmentAddress>('callAugmentAddress');
      const callInput = {
        organization: '',
        site: '',
        room: '',
        rawAddress: rawAddressRoad,
        from: 'fingerFace'
      };
      this.directLogService.logDirect('info', `augmentAddress(road): ${rawAddressRoad}`, '주소 모달');
      resMessage = await requestAugmentAddress(callInput).toPromise();

      if (resMessage.result === 'success') {
        return resMessage.augmentedAddress;
      } else {
        // 도로명 검색으로 모호한 응답(복수의 응답)이 오는 경우 지번 주소로 한 번 더 시도한다.
        callInput.rawAddress = rawAddressJibun;
        this.directLogService.logDirect('info', `augmentAddress(jibun): ${rawAddressJibun}`, '주소 모달');
        resMessage = await requestAugmentAddress(callInput).toPromise();

        if (resMessage.result === 'success') {
          return resMessage.augmentedAddress;
        }

        throw new Error(resMessage.reason);
      }
    } catch (error) {
      if (error.name === 'TimeoutError') {
        throw new Error('주소 확인 실패. 잠시 후 다시 시도해 주세요.');
      } else {
        throw new Error(`주소 확인 중 에러 발생. 잠시 후 다시 시도해 주세요. 사유: ${error.message ?? '알 수 없는 오류'}`);
      }
    }
  }
}
