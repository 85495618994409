/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';

import { ViewModelService } from '../../../core/5/view-model.service';
import { DirectLogService } from '../../../core/5/direct-log.service';

@Component({
  selector: 'app-category-modal',
  templateUrl: './category-modal.component.html',
  styleUrls: ['./category-modal.component.scss']
})
export class CategoryModalComponent implements OnInit {
  @Input() allCategories: string[];
  @Input() categories: string[];
  @Input() setCategories: (categories: string[]) => void;
  isEmpty = false;
  isAllChecked = true;
  checkBoxList: { value: string, isChecked: boolean, amount: number }[] = [];
  totalAmount: number;

  constructor(
    private modalController: ModalController,
    private viewModelService: ViewModelService,
    private directLogService: DirectLogService
  ) { }

  ngOnInit() {
    this.initCheckBoxList();
    this.directLogService.logDirect('popup', '카테고리 모달', '지점 - 모든 업소 메뉴 보기 - 검색 및 설정');
  }

  initCheckBoxList() {
    const { menuViewModel } = this.viewModelService.viewModelForSite[this.viewModelService.currentSite];
    this.checkBoxList = Array(this.allCategories.length);
    this.allCategories.map((cg, index) => (
      this.checkBoxList[index] = {
        value: cg,
        isChecked: this.categories.indexOf(cg) > -1,
        amount: menuViewModel.filter(item => item.category === cg).length
      }
    ));
    this.isAllChecked = (this.checkBoxList.filter(i => i.isChecked).length === this.allCategories.length);
    this.totalAmount = this.checkBoxList.reduce((sum, item) => sum + item.amount, 0);
  }

  handleCheckBox() {
    this.isEmpty = !(this.checkBoxList.filter(i => i.isChecked).length > 0);
    this.isAllChecked = (this.checkBoxList.filter(i => i.isChecked).length === this.allCategories.length);
  }

  toggleSelectAll(value: boolean) {
    this.checkBoxList.forEach(item => item.isChecked = value);
    this.directLogService.logDirect('click', `${value ? '🟢' : '❌'} - 전체 선택`, '카테고리 모달');
  }

  selectCategories() {
    this.directLogService.logDirect('click', '확인', '카테고리 모달');
    const selectedCategories = this.checkBoxList.filter(i => i.isChecked).map(i => i.value);
    this.dismissModal();
    this.setCategories(selectedCategories);
  }

  public dismissModal() {
    this.modalController.dismiss();
  }

  /**
   * 최초 초기화 과정에서 changeEvent가 불리기 때문에 사용자 액션에 따른 log는 별도로 지정한다.
   */
  public logCheckBoxEvent(event: CustomEvent) {
    const target = event.target as HTMLIonCheckboxElement;
    const label = target.id;

    this.directLogService.logDirect('click', `${target.checked ? '❌' : '🟢'} - ${label}`, '카테고리 모달');
  }
}
