/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { setCookie } from '../../../core/2/util';
import { SiteService } from '../../../core/4/site.service';
import { DirectLogService } from '../../../core/5/direct-log.service';

@Component({
  selector: 'app-direct-info-modal',
  templateUrl: './direct-info-modal.component.html',
  styleUrls: ['./direct-info-modal.component.scss']
})
export class DirectInfoModalComponent implements OnInit {
  /**
   * 별도로 유지하는 상태값을 '닫기'로 변경한다.
   */
  @Input() public closed: () => void;
  @Input() public site: string;
  public doNotOpen = false;
  public discountTo = 1300;

  constructor(
    private modalController: ModalController,
    private siteService: SiteService,
    private directLogService: DirectLogService
  ) { }

  ngOnInit(): void {
    this.discountTo = this.siteService.sites[this.site]?.direct?.discount?.[0]?.to ?? 1300;
  }

  public dismissModal() {
    if (this.doNotOpen) {
      setCookie('isDirectPopupShow', 'N', 1);
      this.directLogService.logDirect('click', '다이렉트 소개 모달 닫기 - 24시간 동안 열지 않기');
    } else {
      this.closed();
      this.directLogService.logDirect('click', '다이렉트 소개 모달 닫기');
    }
    this.modalController.dismiss();
  }
}
