/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { environment } from '../../environments/environment';
import { AddressSearchModalComponent } from './modal/address-search-modal/address-search-modal.component';
import { CategoryModalComponent } from './modal/category-modal/category-modal.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { DirectInfoModalComponent } from './modal/direct-info-modal/direct-info-modal.component';


@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,

    IonicModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    AddressSearchModalComponent,
    CategoryModalComponent,
    OrderDetailComponent,
    BackButtonComponent,
    DirectInfoModalComponent
  ],
  exports: [
    OrderDetailComponent,
    BackButtonComponent
  ],
  entryComponents: [
    AddressSearchModalComponent,
    CategoryModalComponent
  ]
})
export class SharedModule { }
