/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import firestore = firebase.firestore;
import { BehaviorSubject, Subscription } from 'rxjs';

import { KiccCardOrder } from '../../schema/1/schema-kicc-api';
import { KiccResultDoc, KiccCardOrderDoc, KiccResultDocs } from '../../schema/2/schema-kicc';
import { UnifiedOrder } from '../../schema/3/schema';


const kiccOrderPath = 'kiccOrder';
const kiccResultPath = 'kiccResult';

@Injectable({
  providedIn: 'root'
})
export class KiccOrderService {
  kiccResult: KiccResultDoc[];
  latestKiccResultSubject = new BehaviorSubject<KiccResultDoc[]>([]);
  subscription: Subscription;

  constructor(private db: AngularFirestore) { }

  /**
   * 결제 요청 정보를 DB에 생성합니다.
   */
  async createKiccOrder(kiccOrder: KiccCardOrder, unifiedOrder: Partial<UnifiedOrder>) {
    const docRef0 = this.db.firestore.collection(kiccOrderPath).doc();
    const id = `kicc-${docRef0.id}`;

    const docRef = this.db.firestore.collection(kiccOrderPath).doc(id);

    const doc: Partial<KiccCardOrderDoc> = {
      ...kiccOrder,
      _id: id,
      _instanceNo: docRef0.id,
      _organization: unifiedOrder.organization,
      _site: unifiedOrder.site,
      _room: unifiedOrder.room,
      _timeCreate: firestore.FieldValue.serverTimestamp() as firestore.Timestamp
    };

    await this.db.doc<Partial<KiccCardOrderDoc>>(docRef).set(doc);
  }

  getKiccResultFor(orderNo: string) {
    const queryFn: QueryFn = ref => {
      return ref.where('order_no', '==', orderNo);
    };

    const collection = this.db.collection<KiccResultDocs>(kiccResultPath, queryFn);
    const kiccResult = collection.get();

    return kiccResult.toPromise();
  }
}
