/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import * as uuidv4 from 'uuid/v4';

// message, log에서 사용한다.
export const instanceId = uuidv4();

export const checkIsDirect = () => {
  // "*.direct.ghostaurant.co"
  // "*.face.ghostaurant.co"
  const { hostname } = location;
  /**
   * URL에 따라서 UI 모드를 테이크아웃 or 배달로 정한다.
   * 향후 배달 서비스가 정착하여 테이크아웃 서비스를 흡수하면 사라질 예정이다.
   */
  // localhost도 direct로 간주한다.
  return hostname.indexOf('face') === -1;
};
