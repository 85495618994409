/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
  CanActivate, Route,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild, CanLoad
} from '@angular/router';

import { RoomService } from './core/4/room.service';
import { SiteService } from './core/4/site.service';
// import { debugLog } from './core/util';

/**
 * 개별 페이지를 표시하기 전에 준비해야 할 것이 있으면 기다리도록 한다.
 * - siteDocs, roomDocs
 */
@Injectable({
  providedIn: 'root'
})
export class InitGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private siteService: SiteService,
    private roomService: RoomService,
  ) { }

  promiseForInit() {
    const siteSubject = this.siteService.latestSubject;
    const roomSubject = this.roomService.latestSubject;

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await combineLatest([siteSubject, roomSubject]).pipe(
          takeWhile(([siteDocs, roomDocs]) =>
            !(Object.keys(siteDocs).length > 0 && Object.keys(roomDocs).length > 0)
          )
        ).forEach(() => { /* 끝나기만 기다릴 뿐 */ });
      } catch (error) {
        reject(error);
      }

      resolve(true);
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // debugLog(`canActivate: '${route.url}'`);

    return this.promiseForInit();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // debugLog(`canActivateChild: '${childRoute.url}'`);

    return this.promiseForInit();
  }

  canLoad(route: Route) {
    // debugLog(`canLoad: '${route.path}'`);

    return this.promiseForInit();
  }
}
