/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { FormModule } from '../form/form.module';
import { SharedModule } from '../shared/shared.module';
import { GridShopComponent } from '../gird/grid-shop/grid-shop.component';
import { GridMenuComponent } from '../gird/grid-menu/grid-menu.component';

import { SitesComponent } from './sites/sites.component';
import { ShopComponent } from './shop/shop.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { HomeComponent } from './home/home.component';
import { MyOrderComponent } from './my-order/my-order.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  declarations: [
    HomeComponent,
    SitesComponent,
    ShopComponent,
    ShoppingCartComponent,
    GridShopComponent,
    GridMenuComponent,
    MyOrderComponent,
    ReceiptComponent,
    PageNotFoundComponent,
  ]
})
export class PagesModule { }
