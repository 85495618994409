/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';

import { FingerDirectConf } from '../../schema/4/schema-direct';

import { UtilService } from '../1/util.service';
import { LogService } from '../3/log.service';

const confCollectionPath = 'conf';

@Injectable({
  providedIn: 'root',
})
export class ConfService {
  public fingerDirectConf: FingerDirectConf;
  public lastestFingerDirectConfSubject = new BehaviorSubject<FingerDirectConf>({
    closed: {}
  });

  constructor(
    private db: AngularFirestore,
    private utilService: UtilService,
    private logService: LogService
  ) { }

  public observeFingerDirectConf() {
    const docRef = this.db.doc<FingerDirectConf>(`${confCollectionPath}/finger-direct`);

    docRef.valueChanges().subscribe(
      (doc) => {
        this.fingerDirectConf = doc;
        this.lastestFingerDirectConfSubject.next(doc);
      },
      (error) => {
        this.utilService.toastError(
          `필수정보를 불러오는데 실패했습니다. 새 창에서 다시 시도해주세요.`
        );
        this.logService.error(`observeFingerFace()에서 에러 발생 : ${error}`);
      }
    );
  }
}
