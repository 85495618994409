/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { SiteDoc, SiteDocs } from '../../schema/3/schema';

import { UserService } from '../2/user.service';
import { calcGeoDistance } from '../2/util';
import { LogService } from '../3/log.service';

const collectionPath = 'site';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  public currentSite: string;
  public sites: SiteDocs = {};
  public latestSubject = new BehaviorSubject<SiteDocs>({});

  constructor(
    private db: AngularFirestore,
    private userService: UserService,
    private logService: LogService
  ) { }

  /**
   * 최신 상태를 유지하며 변화가 있으면 알려준다.
   */
  public observe() {
    const organization = this.userService.organization;
    const queryFn: QueryFn = ref => ref.where('organization', '==', organization);

    const collection = this.db.collection<SiteDoc>(collectionPath, queryFn);
    collection.valueChanges().subscribe(docs => {
      this.sites = docs.reduce((acc, doc) => { acc[doc._id] = doc; return acc; }, {});
      this.latestSubject.next(this.sites);
    });
  }

  public calcDeliveryDistanceAndDiscount(lat: number, lng: number) {
    const site = this.sites[this.currentSite];

    if (site?.lat && site?.lng && lat && lng) {
      const deliveryDistance = calcGeoDistance(site.lat, site.lng, lat, lng);
      const discount = site.direct.discount.sort((a, b) => a.to - b.to).find(i => deliveryDistance <= i.to)?.discountAmount ?? 0;
      return {
        deliveryDistance,
        discount
      };
    } else {
      this.logService.withToastrError(`에러 발생. 위치정보가 없습니다. 지점: ${site}, 고객: ${lat}, ${lng}`);
      return {
        deliveryDistance: 99999,
        discount: 0
      };
    }
  }
}
