/*
 * © 2020 Button Soup, Inc. All rights reserved. <https://ghostkitchen.net>
 */
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';

import { ShopViewModel } from '../../schema/1/schema-view-model';

import { ViewModelService } from '../../core/5/view-model.service';
import { DirectLogService } from '../../core/5/direct-log.service';

@Component({
  selector: 'app-grid-shop',
  templateUrl: './grid-shop.component.html',
  styleUrls: ['./grid-shop.component.scss']
})
export class GridShopComponent implements OnInit, OnDestroy {
  @Output() presentAddressModal = new EventEmitter<string>();
  @Input() showUp: boolean;
  @Input() site: string;
  shopViewModel: ShopViewModel[];
  isLoading = true;
  dummyList = [];

  private viewModelSubscription: Subscription;

  constructor(
    private viewModelService: ViewModelService,
    private router: Router,
    private directLogService: DirectLogService
  ) { }

  ngOnInit() {
    this.setPlaceHolder();
    this.observeViewModel();
  }

  ngOnDestroy() {
    if (this.viewModelSubscription) {
      this.viewModelSubscription.unsubscribe();
    }
  }

  public goShop(shopNo: string, shopName: string) {
    this.directLogService.logDirect('click', '업소: ' + shopName, '지점 - 업소보기');
    this.router.navigate([this.site, shopNo]);
  }

  private observeViewModel() {
    this.viewModelSubscription = this.viewModelService.latestShopViewModelForSiteSubject
      .pipe(
        tap(viewModel => this.isLoading = viewModel === undefined),
        filter(viewModel => viewModel !== undefined && viewModel[this.viewModelService.currentSite] !== undefined))
      .subscribe(viewModel => {
        const { shopViewModel } = viewModel[this.viewModelService.currentSite];
        this.shopViewModel = shopViewModel;

        this.isLoading = this.site !== this.viewModelService.currentSite;
      });
  }

  /**
   * 요청에 따른 이미지를 로드하기 전에 임시로 보여줄 타일을 만들어둡니다.
   */
  private setPlaceHolder(amount: number = 8) {
    const dummyItem = [];
    for (let i = 0; i < amount; i++) {
      dummyItem.push('placeholder for shop');
    }
    this.dummyList = dummyItem;
  }
}
